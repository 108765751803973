import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Label,
  Container,
  Modal,
  Spinner,
  Form,
  CardImg,
} from "reactstrap";
import { useHistory } from "react-router-dom";

//Import Calander
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import moment from "moment";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";

const EditEvents = (props) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [events, setEvents] = useState();
  const [modal_backdrop1, setmodal_backdrop1] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState({});
  const [name, setName] = useState("");
  const [requestTitle, setRequestTitle] = useState("");
  const [coverImg, setCoverImg] = useState();
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [eventId, setEventId] = useState("");
  const [formattedEvents, setFormattedEvents] = useState([]);

  const [startDateTimeError, setStartDateTimeError] = useState(false);
  const [lastDateTimeError, setLastDateTimeError] = useState(false);
  const [responseTime, setResponseTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const history = useHistory();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to format date to "YYYY-MM-DDTHH:mm" format
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [startDateTime, setStartDateTime] = useState(
    formatDateTime(new Date())
  );
  const [lastDateTime, setLastDateTime] = useState(formatDateTime(new Date()));

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // For GET the event list
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    // Function to fetch events data from the server
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        fetch(
          `${REACT_APP_API_ENDPOINT}/event/paginate?page=${currentPage}&pageSize=${pageSize}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((res) => {
            const { events, pagination } = res;
            setEvents(events);
            setTotalPages(pagination.totalPages);
            // Map events to FullCalendar compatible format
            const formattedEvents = events.map((event) => {
              return {
                title: event.name,
                start: event.startDateTime,
                end: event.lastDateTime,
              };
            });
            setFormattedEvents(formattedEvents);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, [currentPage, pageSize]);
  /* useEffect(() => {
    const startTime = new Date().getTime();
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/event`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const endTime = new Date().getTime();
        const timeTakenInMilliseconds = endTime - startTime;
        const timeTakenInSeconds = (timeTakenInMilliseconds / 1000).toFixed(2); // Convert milliseconds to seconds with 2 decimal places
        setResponseTime(timeTakenInSeconds);
        //alert(timeTakenInSeconds);
        setEvents(result);
        // Map events to FullCalendar compatible format
        const formattedEvents = result.map((event) => {
          return {
            title: event.name,
            start: event.startDateTime,
            end: event.lastDateTime,
          };
        });
        setFormattedEvents(formattedEvents);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);*/

  // For Update the event list (PUT)
  function changeEvent(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    if (coverImg && coverImg.size > MAX_IMAGE_SIZE) {
      alert("Please upload image upto 100KB in size.");
      setCoverImg();
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("lastDateTime", lastDateTime);
    formData.append("startDateTime", startDateTime);
    formData.append("location", location);
    formData.append("description", description);
    formData.append("isOnline", isOnline);
    formData.append("eventId", _id);
    formData.append("organiser", requestTitle);
    formData.append("coverImg", coverImg);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/event`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(""))
      .catch((error) => console.log("error", error));
    window.open("/edit-events", "_self");
  }

  // For Deleting the events

  function deleteEvent(_id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", jwt);
    var raw = JSON.stringify({
      eventId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    //console.log(_id);
    fetch(`${REACT_APP_API_ENDPOINT}/event`, requestOptions).then((result) => {
      result.json().then((response) => {
        //console.warn(response);
        window.open("/edit-events", "_self");
      });
    });
  }

  const handleStartDateTimeChange = (e) => {
    const selectedDateTime = new Date(e.target.value);
    const now = new Date();

    if (selectedDateTime < now) {
      // Prevent selection of previous date from today
      // You can display an error message or take other actions here
      setStartDateTimeError(true);
      return;
    }

    if (selectedDateTime > new Date(lastDateTime)) {
      // Prevent selection of date greater than lastDateTime
      // You can display an error message or take other actions here
      setStartDateTimeError(true);
      return;
    }

    setStartDateTimeError(false);
    setStartDateTime(e.target.value);
  };

  const handleLastDateTimeChange = (e) => {
    const selectedDateTime = new Date(e.target.value);
    const now = new Date();

    if (selectedDateTime < now) {
      // Prevent selection of previous date from today
      // You can display an error message or take other actions here
      setLastDateTimeError(true);
      return;
    }

    if (selectedDateTime < new Date(startDateTime)) {
      // Prevent selection of date smaller than startDateTime
      // You can display an error message or take other actions here
      setLastDateTimeError(true);
      return;
    }

    setLastDateTimeError(false);
    setLastDateTime(e.target.value);
  };

  function togBackdrop(eventId) {
    setModalBackdrop((prevBackdrop) => ({
      ...prevBackdrop,
      [eventId]: !prevBackdrop[eventId],
    }));
    removeBodyCss();
  }

  function tog_backdrop1() {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>

          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 85,
                }}
              >
                <Col sm={6}>
                  <h3>Edit Event</h3>
                </Col>
                <Col sm={6}>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="search-box ml-2">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-white border-light"
                            style={{ borderRadius: 15 }}
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />

                          <i className="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>

              <>
                <Row className="p-3">
                  <Col mg={12} xl={12}>
                    <Row style={{ marginBottom: 10 }}>
                      {/* Pagination controls */}
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          style={{ fontSize: 16 }}
                        >
                          <i className="uil uil-arrow-left"></i>
                          Prev
                        </Button>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: 16 }}>
                            {currentPage + "/" + totalPages}
                          </span>
                        </div>

                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          style={{ fontSize: 16 }}
                        >
                          Next
                          <i className="uil uil-arrow-right"></i>
                        </Button>
                      </Col>
                    </Row>
                    {isLoading ? (
                      <Button
                        color="primary"
                        style={{ position: "absolute", top: 200, left: "50%" }}
                        disabled
                      >
                        <Spinner size="sm">Loading...</Spinner>
                        <span> Loading</span>
                      </Button>
                    ) : (
                      <>
                        <Row>
                          {events &&
                            events
                              .filter((event) =>
                                event.name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((event, id) => (
                                <Col key={id} mg={4} xl={3}>
                                  <Card
                                    style={{
                                      borderRadius: 15,
                                    }}
                                  >
                                    <CardBody>
                                      <CardTitle className="h4 mt-0">
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <CardImg
                                            className="img-fluid"
                                            src={`data:image/png;base64,${event.coverImg}`}
                                            style={{
                                              height: 100,
                                              objectFit: "contain",
                                            }}
                                            alt="Event banner"
                                          />
                                        </div>
                                      </CardTitle>
                                      <CardSubtitle className="h5 mt-3">
                                        {event ? event.name : null}
                                      </CardSubtitle>
                                      <CardSubtitle
                                        style={{
                                          marginTop: 1,
                                        }}
                                      >
                                        {event ? event.location : null}
                                      </CardSubtitle>

                                      <CardSubtitle className="mt-3">
                                        {event
                                          ? moment(event.startDateTime).format(
                                              "MMM DD, YYYY hh:mm A"
                                            )
                                          : null}{" "}
                                        -{" "}
                                        {event
                                          ? moment(event.lastDateTime).format(
                                              "MMM DD, YYYY hh:mm A"
                                            )
                                          : null}{" "}
                                      </CardSubtitle>
                                      <br />
                                      <Row
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Col
                                          md={6}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light m-1"
                                            onClick={() => {
                                              setLastDateTime(
                                                formatDateTime(
                                                  new Date(event.lastDateTime)
                                                )
                                              );
                                              setStartDateTime(
                                                formatDateTime(
                                                  new Date(event.startDateTime)
                                                )
                                              );
                                              setName(event.name);
                                              setRequestTitle(event.organiser);
                                              setLocation(event.location);
                                              setDescription(event.description);
                                              setIsOnline(event.isOnline);
                                              setEventId(event._id);
                                              togBackdrop(event._id);
                                            }}
                                            data-toggle="modal"
                                            style={{
                                              width: 40,
                                              fontSize: 17,
                                              height: 40,
                                              borderRadius: "50%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <i className="uil uil-edit"></i>
                                          </Button>
                                          <Modal
                                            isOpen={modalBackdrop[event._id]} // Use the state variable to control the modal visibility
                                            toggle={() =>
                                              togBackdrop(event._id)
                                            }
                                            scrollable={true}
                                            id="staticBackdrop"
                                          >
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="staticBackdropLabel"
                                              >
                                                Edit Events
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                onClick={() => {
                                                  togBackdrop(event._id);
                                                }}
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <Form>
                                                <Row>
                                                  <Col md={6}>
                                                    <div className="form-check mb-3">
                                                      <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="onlineOfflineRadio"
                                                        id="onlineRadio"
                                                        value="true"
                                                        checked={isOnline}
                                                        onChange={(e) =>
                                                          setIsOnline(
                                                            e.target.value ===
                                                              "true"
                                                          )
                                                        }
                                                      />
                                                      <Label
                                                        className="form-check-label"
                                                        htmlFor="onlineRadio"
                                                      >
                                                        Online
                                                      </Label>
                                                    </div>
                                                  </Col>

                                                  <Col md={6}>
                                                    <div className="form-check">
                                                      <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="onlineOfflineRadio"
                                                        id="offlineRadio"
                                                        value="false"
                                                        checked={!isOnline}
                                                        onChange={(e) =>
                                                          setIsOnline(
                                                            e.target.value ===
                                                              "true"
                                                          )
                                                        }
                                                      />
                                                      <Label
                                                        className="form-check-label"
                                                        htmlFor="offlineRadio"
                                                      >
                                                        Offline
                                                      </Label>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col className="mb-3">
                                                    <Label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Event Name*
                                                    </Label>
                                                    <div>
                                                      <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={name}
                                                        required={true}
                                                        onChange={(e) =>
                                                          setName(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col className="mb-3">
                                                    <Label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Organiser's Name*
                                                    </Label>
                                                    <div>
                                                      <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={requestTitle}
                                                        required={true}
                                                        onChange={(e) =>
                                                          setRequestTitle(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col>
                                                    <div className="mb-3">
                                                      <Label>
                                                        Description*
                                                      </Label>
                                                      <Input
                                                        type="textarea"
                                                        id="textarea"
                                                        value={description}
                                                        onChange={(e) =>
                                                          setDescription(
                                                            e.target.value
                                                          )
                                                        }
                                                        maxLength="225"
                                                        rows="5"
                                                        placeholder="This Description has a limit of 400 chars."
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                  <Col md={6}>
                                                    <Label>
                                                      Start date & Time*
                                                    </Label>
                                                    <div className="col-md-10">
                                                      <Input
                                                        className={`form-control ${
                                                          startDateTimeError
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                        type="datetime-local"
                                                        value={startDateTime}
                                                        required
                                                        onChange={
                                                          handleStartDateTimeChange
                                                        }
                                                        id="start-datetime-local-input"
                                                        style={{
                                                          paddingRight: "5px",
                                                        }} // add padding to the right side
                                                      />
                                                      {startDateTimeError && (
                                                        <div className="invalid-feedback">
                                                          Invalid date selection
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Col>
                                                  <Col md={6}>
                                                    <Label>
                                                      End date & Time*
                                                    </Label>
                                                    <div className="col-md-10">
                                                      <Input
                                                        className={`form-control ${
                                                          lastDateTimeError
                                                            ? "is-invalid"
                                                            : ""
                                                        }`}
                                                        type="datetime-local"
                                                        value={lastDateTime}
                                                        required
                                                        onChange={
                                                          handleLastDateTimeChange
                                                        }
                                                        id="end-datetime-local-input"
                                                        style={{
                                                          paddingRight: "5px",
                                                        }} // add padding to the right side
                                                      />
                                                      {lastDateTimeError && (
                                                        <div className="invalid-feedback">
                                                          Invalid date selection
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col className="mb-3">
                                                    <Label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Location*
                                                    </Label>
                                                    <div>
                                                      <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={location}
                                                        required={true}
                                                        onChange={(e) =>
                                                          setLocation(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>

                                                <Row>
                                                  <div className="row justify-content-center">
                                                    <Col className="mb-3">
                                                      <Label htmlFor="resume">
                                                        Cover Image / Video
                                                      </Label>
                                                      <Input
                                                        type="file"
                                                        className="form-control"
                                                        id="resume"
                                                        multiple
                                                        onChange={(e) =>
                                                          setCoverImg(
                                                            e.target.files[0]
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                  </div>
                                                </Row>
                                              </Form>
                                            </div>
                                            <div className="modal-footer">
                                              <Button
                                                color="primary"
                                                className={`btn btn-primary ${
                                                  name &&
                                                  requestTitle &&
                                                  description &&
                                                  startDateTime &&
                                                  lastDateTime &&
                                                  location &&
                                                  coverImg !== undefined
                                                    ? ""
                                                    : "disabled"
                                                }`}
                                                onClick={() =>
                                                  changeEvent(
                                                    event && event._id
                                                  )
                                                }
                                              >
                                                Update
                                              </Button>
                                            </div>
                                          </Modal>
                                        </Col>
                                        <Col
                                          md={6}
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            color="primary"
                                            className="btn btn-primary waves-effect waves-light m-1"
                                            onClick={() =>
                                              deleteEvent(event && event._id)
                                            }
                                            style={{
                                              width: 40,
                                              fontSize: 17,
                                              height: 40,
                                              borderRadius: "50%",

                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <i className="uil uil-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              ))}
                        </Row>
                        {events &&
                        events.filter((event) =>
                          event.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ).length > 0 ? (
                          <Row>
                            {/* Pagination controls */}
                            <Col
                              md={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                style={{ fontSize: 16 }}
                              >
                                <i className="uil uil-arrow-left"></i>
                                Prev
                              </Button>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontSize: 16 }}>
                                  {currentPage + "/" + totalPages}
                                </span>
                              </div>
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                style={{ fontSize: 16 }}
                              >
                                Next
                                <i className="uil uil-arrow-right"></i>
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col sm={12}>
                              <h3 style={{ textAlign: "center" }}>
                                No data available.
                              </h3>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </Col>
                  {/* <Col mg={4} xl={4}>
                    <Col md="12">
                      <div className="form-inline">
                        <div className="search-box ml-2">
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control bg-white border-light"
                              style={{ borderRadius: 15 }}
                              placeholder="Search..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            <i className="mdi mdi-magnify search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="12" className="mt-2">
                      <Card
                        style={{
                          borderRadius: 20,
                        }}
                      >
                        <CardBody>
                          <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={formattedEvents && formattedEvents} // Pass the formatted events to FullCalendar
                            slotDuration={"00:15:00"}
                            handleWindowResize={true}
                            themeSystem="bootstrap"
                            // editable={true}
                            // droppable={true}
                            // selectable={true}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Col> */}
                </Row>
              </>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EditEvents;
