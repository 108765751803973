import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  Button,
  TabContent,
  Modal,
  ModalBody,
  Spinner,
  TabPane,
  Table,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

const Career = () => {
  //form Data
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [workplaceType, setWorkplaceType] = useState("On-Site");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState("part_time");
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [logo, setLogo] = useState();
  const [experience, setExperience] = useState("");

  const history = useHistory();

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  const addCareer = () => {
    setIsLoading(true);
    toggle();
    console.warn({
      jobTitle,
      company,
      workplaceType,
      description,
      location,
      jobType,
    });
    let data = {
      jobTitle,
      company,
      workplaceType,
      description,
      location,
      jobType,
      role,
      email,
      experience,
      logo,
    };
    fetch(`${REACT_APP_API_ENDPOINT}/career/job`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      // console.warn("result", result);
      result.json().then((resp) => {
        setIsLoading(false);
        setModal(false);
        if (result.status === 200) {
          console.warn("resp", resp);
          window.open("/edit-career", "_self");
        } else {
          alert("please enter correct credentials");
        }
      });
    });
  };

  function tog_backdrop() {
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [preview, setPreview] = useState({ imgSrc: null });
  const setSelectedFileformat = (file) => {
    //setSelectedFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      // Convert image to base64 and set the state
      let data = reader.result;
      setLogo(data.split(",")[1]);

      //console.log(reader.result);
    };

    // Read the image as a data URL
    reader.readAsDataURL(file[0]);

    const promiseArr = [];
    for (let i = 0; i < file.length; i++) {
      if (file.size > 100 * 1024) {
        alert(`Image ${file.name} size cannot be more than 100KB.`);
        break;
      }
      promiseArr.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = async () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
        })
      );
    }
    Promise.all(promiseArr).then((result) => {
      setPreview({
        imgSrc: [...result],
      });
    });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      spinner={
        <Spinner style={{ position: "absolute", left: "60%" }}></Spinner>
      }
    >
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>
            <Col md={10}>
              <Container fluid={true}>
                <Header />
                {/*<Row
                style={{
                  marginLeft: 10,
                }}
              >
                <h3>Add job opening</h3>
              </Row>*/}

                <Row
                  style={{
                    marginTop: 85,
                  }}
                >
                  <Col lg={2}>{""}</Col>
                  <Col lg={8}>
                    <Card>
                      <CardBody>
                        <div className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem>
                                <NavLink className="h4">
                                  Tell us who you are hiring{" "}
                                </NavLink>
                              </NavItem>
                            </ul>
                          </div>
                          <div className="content clearfix">
                            <div className="body">
                              <TabContent>
                                <TabPane>
                                  <Form>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Job Title*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={jobTitle}
                                            onChange={(e) =>
                                              setJobTitle(e.target.value)
                                            }
                                            maxLength="125"
                                            rows="1"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Role*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={role}
                                            onChange={(e) =>
                                              setRole(e.target.value)
                                            }
                                            maxLength="125"
                                            rows="1"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Company Name*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={company}
                                            onChange={(e) =>
                                              setCompany(e.target.value)
                                            }
                                            maxLength="125"
                                            rows="1"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label htmlFor="resume">Logo</Label>
                                        <Input
                                          type="file"
                                          className="form-control"
                                          id="resume"
                                          onChange={(e) =>
                                            setSelectedFileformat(
                                              e.target.files
                                            )
                                          }
                                        />
                                      </Col>
                                      <div className="table-responsive">
                                        <Table className="table mb-0">
                                          <thead>
                                            {preview.imgSrc?.map(
                                              (image, index) => (
                                                <tr key={index}>
                                                  <th>
                                                    <img
                                                      className="rounded avatar-lg"
                                                      alt=""
                                                      src={image}
                                                    />
                                                  </th>
                                                </tr>
                                              )
                                            )}
                                          </thead>
                                        </Table>
                                      </div>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Email*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={email}
                                            onChange={(e) =>
                                              setEmail(e.target.value)
                                            }
                                            maxLength="40"
                                            rows="1"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label>Workplace type*</Label>
                                        <Form.Select
                                          aria-label="Default select example"
                                          className="form-control"
                                          value={workplaceType}
                                          required={true}
                                          onChange={(e) =>
                                            setWorkplaceType(e.target.value)
                                          }
                                        >
                                          <option
                                            value="On-Site"
                                            style={{
                                              fontSize: 16,
                                              marginTop: 4,
                                            }}
                                          >
                                            On-Site
                                          </option>
                                          <option
                                            value="Hybrid"
                                            style={{
                                              fontSize: 16,
                                              marginTop: 4,
                                            }}
                                          >
                                            Hybrid
                                          </option>
                                          <option
                                            value="Remote"
                                            style={{
                                              fontSize: 16,
                                              marginTop: 4,
                                            }}
                                          >
                                            Remote
                                          </option>
                                        </Form.Select>
                                        <Form.Text className="text-muted">
                                          Please select the type of workplace.
                                        </Form.Text>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Job Location*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={location}
                                            onChange={(e) =>
                                              setLocation(e.target.value)
                                            }
                                            maxLength="125"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label>Job type*</Label>
                                        <Form.Select
                                          aria-label="Default select example"
                                          className="form-control"
                                          value={jobType}
                                          onChange={(e) =>
                                            setJobType(e.target.value)
                                          }
                                          required={true}
                                        >
                                          <option>Part_Time</option>
                                          <option>Full_Time</option>
                                          <option>Contract</option>
                                          <option>Temporary</option>

                                          <option>Volunteer</option>
                                          <option>Internship</option>
                                          <option>Other</option>
                                        </Form.Select>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Experience*</Label>

                                          <Input
                                            type="number"
                                            id="textarea"
                                            value={experience}
                                            onChange={(e) =>
                                              setExperience(e.target.value)
                                            }
                                            maxLength="2"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Description*</Label>

                                          <Input
                                            type="textarea"
                                            id="textarea"
                                            value={description}
                                            onChange={(e) =>
                                              setDescription(e.target.value)
                                            }
                                            maxLength="1000"
                                            rows="20"
                                            placeholder="Please enter your text"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="text-center">
                                      <Col>
                                        <Button
                                          color="primary"
                                          onClick={() => addCareer()}
                                          className={`btn btn-primary waves-effect waves-light ${
                                            jobTitle &&
                                            company &&
                                            workplaceType &&
                                            location &&
                                            jobType &&
                                            description &&
                                            role &&
                                            email &&
                                            experience &&
                                            logo !== undefined
                                              ? ""
                                              : "disabled"
                                          }`}
                                        >
                                          Post
                                        </Button>{" "}
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>
                              </TabContent>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={2}>{""}</Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </LoadingOverlay>
  );
};

export default Career;
