//const NODE_ENV = process.env.NODE_ENV || 'development';
let pgURL;
let image_url;


pgURL = 'https://mobileapiv2.ezyfind.co.za/graphql';
image_url = 'https://www.ezyfind.co.za/Documents/';
 

export const RECRUIT_URL = pgURL;
export const PRODUCT_IMAGE_URL = image_url;
