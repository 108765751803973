import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Button,
  TabContent,
  TabPane,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import LoadingOverlay from "react-loading-overlay";

const AddTopStories = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [selectedfile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  const postStory = () => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidUrl = urlPattern.test(link);
    if (isValidUrl) {
      if (selectedfile && selectedfile.size > MAX_IMAGE_SIZE) {
        alert("Please upload image upto 100KB in size.");
        setSelectedFile();
        return;
      }
      setIsLoading(true);
      var myHeaders = new Headers();
      const jwt = localStorage.getItem("jwt");
      console.log(localStorage.getItem("jwt"));
      myHeaders.append("x-api-key", jwt);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("link", link);
      formData.append("coverImg", selectedfile);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      fetch(`${REACT_APP_API_ENDPOINT}/stories`, requestOptions).then(
        (result) => {
          result.json().then((resp) => {
            if (result.status === 200) {
              setShowSuccessPopup(true); // Show the success popup
              setTimeout(() => {
                setIsLoading(false);
                setShowSuccessPopup(false); // Hide the success popup after 3 seconds
              }, 500);
              window.open("/edit-top-stories", "_self");
              // Other code...
            } else {
              alert("Please fill required details!");
            }
          });
        }
      );
    } else {
      alert("Please enter a valid link. Ex: https://www.xyz.com");
    }
  };

  useEffect(() => {
    if (showSuccessPopup) {
      setTimeout(() => {
        setShowSuccessPopup(false); // Hide the success popup after 3 seconds
      }, 2000);
    }
  }, [showSuccessPopup]);

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [preview, setPreview] = useState({ imgSrc: null });
  const setSelectedFileformat = (file) => {
    setSelectedFile(file);
    const promiseArr = [];
    for (let i = 0; i < file.length; i++) {
      if (file.size > 100 * 1024) {
        alert(`Image ${file.name} size cannot be more than 100Kb.`);
        break;
      }
      promiseArr.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = async () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
        })
      );
    }
    Promise.all(promiseArr).then((result) => {
      setPreview({
        ...preview,
        imgSrc: preview.imgSrc ? [...preview.imgSrc, ...result] : [...result],
      });
    });
  };
  return (
    <LoadingOverlay
      active={isLoading}
      spinner={
        <Spinner style={{ position: "absolute", left: "60%" }}></Spinner>
      }
    >
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>
            <Col md={10}>
              <Container fluid={true}>
                <Header />

                <Row style={{ marginTop: 85 }}>
                  <Col lg={2}></Col>
                  <Col lg={8}>
                    <Card>
                      <CardBody>
                        <div className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem>
                                <NavLink className="h4">
                                  Upload TopStories
                                </NavLink>
                              </NavItem>
                            </ul>
                          </div>
                          {showSuccessPopup && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                                transform: [
                                  { translateX: "-50%" },
                                  { translateY: "-50%" },
                                ],
                                backgroundColor: "#28a745",
                                color: "#fff",
                                padding: 10,
                                borderRadius: 4,
                                fontSize: 16,
                                fontWeight: "bold",
                                shadowColor: "#000",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.2,
                                shadowRadius: 4,
                                elevation: 3,
                                zIndex: 9999,
                              }}
                            >
                              Story successfully uploaded
                            </div>
                          )}
                          <div className="content clearfix">
                            <div className="body">
                              <TabContent>
                                <TabPane>
                                  <Form>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Story Title*</Label>

                                          <Input
                                            type="text"
                                            id="textarea"
                                            value={title}
                                            onChange={(e) =>
                                              setTitle(e.target.value)
                                            }
                                            maxLength="125"
                                            rows="1"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>Description*</Label>

                                          <Input
                                            type="textarea"
                                            id="textarea"
                                            value={description}
                                            onChange={(e) =>
                                              setDescription(e.target.value)
                                            }
                                            maxLength="225"
                                            rows="5"
                                            placeholder="This Description has a limit of 400 chars."
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label
                                          htmlFor="example-text-input"
                                          className="col-form-label"
                                        >
                                          Video Link*
                                        </Label>
                                        <div>
                                          <Input
                                            className="form-control"
                                            type="url"
                                            value={link}
                                            onChange={(e) =>
                                              setLink(e.target.value)
                                            }
                                            defaultValue="https://ads.google.com"
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <div className="row justify-content-center">
                                        <Col className="mb-3">
                                          <Label htmlFor="resume">
                                            Cover Image*
                                          </Label>
                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="resume"
                                            onChange={(e) =>
                                              setSelectedFileformat(
                                                e.target.files[0]
                                              )
                                            }
                                          />
                                        </Col>
                                      </div>
                                      {/* <div className="table-responsive">
                                  <Table className="table mb-0">
                                    <thead>
                                      {preview.imgSrc?.map((image, index) => (
                                        <tr key={index}>
                                          <th>
                                            <img
                                              className="rounded avatar-lg"
                                              alt=""
                                              src={image}
                                            />
                                          </th>
                                        </tr>
                                      ))}
                                    </thead>
                                  </Table>
                                </div> */}
                                    </Row>

                                    <Row className="text-center">
                                      <Col>
                                        <Button
                                          color="primary"
                                          // disabled={itemRequestLoading}
                                          onClick={() => postStory()}
                                          className={`btn btn-primary waves-effect waves-light ${
                                            title &&
                                            description &&
                                            link &&
                                            selectedfile !== undefined
                                              ? ""
                                              : "disabled"
                                          }`}
                                        >
                                          Post
                                        </Button>{" "}
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>
                              </TabContent>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={2}> </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </LoadingOverlay>
  );
};

export default AddTopStories;
