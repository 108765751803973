import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Button,
  Modal,
  Input,
  Label,
  CardTitle,
  ButtonDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
const Association = () => {
  const [textcount, settextcount] = useState(0);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  // Pagination state
  const [startIndex, setStartIndex] = useState(0);

  //form Data
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [drp_danger1, setDrp_danger1] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [president, setPresident] = useState("");
  const [contact, setContact] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [description, setDescription] = useState("");
  const [doctor, setDoctor] = useState();
  const [selectedfile, setSelectedFile] = useState([]);
  const [image, setImage] = useState();
  const [association, setAssociation] = useState([]);
  // Search state
  const [searchPresident, setSearchPresident] = useState("");
  const [searchName, setSearchName] = useState("");
  const [filteredAssociations, setFilteredAssociations] = useState([]);
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const filteredList = association
    .filter((item) =>
      item.name?.toLowerCase().includes(searchName.toLowerCase())
    )
    .filter((item) =>
      item.president?.toLowerCase().includes(searchPresident.toLowerCase())
    );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  myHeaders.append("x-api-key", jwt);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user`, requestOptions)
      .then((response) => response.json())
      .then((result) => setProfile(result))
      .catch((error) => console.log("error", error));
  }, []);
  const getUserByEmail = (email) => {
    if (!profile) return {};
    //console.log(profile);
    let u = profile.find((u) => u.email === email);
    return u;
  };
  // POST association api here
  const addAssociation = () => {
    if (image && image.size > MAX_IMAGE_SIZE) {
      alert("Please upload image upto 100KB in size.");
      setImage();
      return;
    }
    var myHeaders = new Headers();

    //console.log(localStorage.getItem("jwt"));
    myHeaders.append("x-api-key", jwt);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emailId);
    const phoneRegex = /^[1-9]\d{9}$/;
    const isValidContact = phoneRegex.test(contact);
    if (isValidEmail) {
      if (isValidContact) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("president", president);
        formData.append("emailId", emailId);
        formData.append("contact", contact);
        formData.append("city", city);
        formData.append("description", description);
        formData.append("coverImg", image);
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
          fetch(`${REACT_APP_API_ENDPOINT}/association`, {
            method: "POST",
            headers: myHeaders,
            body: formData,
          }).then((result) => {
            result.json().then((resp) => {
              if (result.status == 200) {
                console.warn("resp", resp);
                localStorage.setItem("isAssociation", true);
                window.open("/association", "_self");
              } else {
                alert("please enter correct credentials");
              }
            });
          });
        }, 3000);
      } else {
        alert("Please enter valid contact");
      }
    } else {
      alert("Please enter valid email!");
    }
  };

  // Doctors GET api for showing list(number of doctors)
  var myHeaders = new Headers();

  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/doctor`, requestOptions)
      .then((response) => response.json())
      .then((result) => setDoctor(result))
      .catch((error) => console.log("error", error));
  }, []);

  // Association Get api here
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/association`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        setAssociation(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const paginate = (pageNumber) => {
    setStartIndex((pageNumber - 1) * itemsPerPage);
    setCurrentPage(pageNumber);
  };

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const handleInputChange = (e) => {
    setDescription(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />

              <Row
                style={{
                  marginTop: 85,
                }}
              >
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>
                        <h3>Associations</h3>
                      </CardTitle>
                      {isLoading ? (
                        <Button
                          color="primary"
                          style={{
                            position: "absolute",
                            top: 200,
                            left: "50%",
                          }}
                          disabled
                        >
                          <Spinner size="sm">Loading...</Spinner>
                          <span> Loading</span>
                        </Button>
                      ) : (
                        <>
                          <Row>
                            <Col md={3} className="mb-2">
                              <Button id="caret" color="primary">
                                Total No. of Associations
                                <h4 style={{ color: "#fff" }}>
                                  {filteredList.length}
                                </h4>
                              </Button>
                            </Col>
                            <Col md={3}>
                              <div className="search-input">
                                <input
                                  type="text"
                                  value={searchPresident}
                                  onChange={(e) =>
                                    setSearchPresident(e.target.value)
                                  }
                                  placeholder="Search by President Name"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="search-input">
                                <input
                                  type="text"
                                  value={searchName}
                                  onChange={(e) =>
                                    setSearchName(e.target.value)
                                  }
                                  placeholder="Search by Association Name"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md={1} />
                            <Col md={2}>
                              <div className="mt-1">
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() => {
                                    tog_backdrop();
                                  }}
                                  data-toggle="modal"
                                >
                                  <i className="dripicons-plus" /> Add
                                  Association
                                </button>
                                <Modal
                                  isOpen={modal_backdrop}
                                  toggle={() => {
                                    tog_backdrop();
                                  }}
                                  scrollable={true}
                                  id="staticBackdrop"
                                >
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="staticBackdropLabel"
                                    >
                                      Add an association
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      onClick={() => {
                                        setmodal_backdrop(false);
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <Form>
                                      <Row>
                                        <Col className="mb-1">
                                          <Label
                                            htmlFor="example-text-input"
                                            className="col-form-label"
                                          >
                                            Association Name*
                                          </Label>
                                          <div>
                                            <Input
                                              className="form-control"
                                              name="name"
                                              onChange={(e) =>
                                                setName(e.target.value)
                                              }
                                              value={name}
                                              type="text"
                                              required
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col className="mb-3">
                                          <Label
                                            htmlFor="description-input"
                                            className="col-form-label"
                                          >
                                            Description*
                                          </Label>
                                          <div>
                                            <textarea
                                              className="form-control"
                                              name="description"
                                              onChange={handleInputChange}
                                              value={description}
                                              required
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col className="mb-3">
                                          <Label htmlFor="resume">
                                            Cover Image
                                          </Label>
                                          <Input
                                            type="file"
                                            className="form-control"
                                            id="resume"
                                            onChange={(e) => {
                                              setImage(e.target.files[0]);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="mb-1">
                                          <Label
                                            htmlFor="example-text-input"
                                            className="col-form-label"
                                          >
                                            President Name*
                                          </Label>
                                          <div>
                                            <Input
                                              className="form-control"
                                              name="name"
                                              onChange={(e) =>
                                                setPresident(e.target.value)
                                              }
                                              value={president}
                                              type="text"
                                              required
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="mb-1">
                                          <Label
                                            htmlFor="example-text-input"
                                            className="col-form-label"
                                          >
                                            Contact No.
                                          </Label>
                                          <div>
                                            <Input
                                              className="form-control"
                                              name="contact"
                                              onChange={(e) =>
                                                setContact(e.target.value)
                                              }
                                              value={contact}
                                              type="text"
                                              maxLength={10}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="mb-1">
                                          <Label
                                            htmlFor="example-text-input"
                                            className="col-form-label"
                                          >
                                            City*
                                          </Label>
                                          <div>
                                            <Input
                                              className="form-control"
                                              name="city"
                                              onChange={(e) =>
                                                setCity(e.target.value)
                                              }
                                              value={city}
                                              type="text"
                                              required
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="mb-1">
                                          <Label
                                            htmlFor="email-input"
                                            className="col-form-label"
                                          >
                                            President Email*
                                          </Label>
                                          <div>
                                            <Input
                                              className="form-control"
                                              name="email"
                                              onChange={(e) =>
                                                setEmailId(e.target.value)
                                              }
                                              value={emailId}
                                              type="email"
                                              required
                                            />
                                            {emailId !== "" &&
                                              !/^\S+@\S+\.\S+$/.test(
                                                emailId
                                              ) && (
                                                <div className="invalid-feedback">
                                                  Please enter a valid email
                                                  address in the format
                                                  name@example.com
                                                </div>
                                              )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className={`btn btn-primary ${
                                        name &&
                                        description &&
                                        president &&
                                        contact &&
                                        city &&
                                        emailId &&
                                        image !== undefined
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={addAssociation}
                                    >
                                      Done
                                    </button>
                                  </div>
                                  {showConfirmation && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: 999,
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: 300,
                                          height: 100,
                                          backgroundColor: "white",
                                          borderWidth: 1,
                                          borderColor: "#ccc",
                                          borderRadius: 4,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: 16,
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        Email sent to the president.
                                      </div>
                                    </div>
                                  )}
                                </Modal>
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <table className="table table-bordered border-dark mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>Profile Photo</th>
                                  <th>President Name</th>
                                  <th>President Email</th>
                                  <th>Association Name</th>
                                  <th>Contact No.</th>
                                  <th>City</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems &&
                                  currentItems.map((index, id) => (
                                    <tr key={id}>
                                      <th scope="row">
                                        {(currentPage - 1) * itemsPerPage +
                                          id +
                                          1}
                                      </th>
                                      <td>
                                        <img
                                          src={
                                            index.coverImg
                                              ? `data:image/png;base64,${index.coverImg}`
                                              : avatar4
                                          }
                                          className="rounded avatar-md"
                                          alt={`${index.name} president`}
                                        />
                                      </td>
                                      <td> {index ? index.president : null}</td>
                                      <td>{index ? index.emailId : null}</td>
                                      <td>{index ? index.name : null}</td>
                                      <td>{index ? index.contact : null}</td>
                                      <td>{index ? index.city : null}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {/* Pagination */}
                          <div className="d-flex justify-content-center">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className={`page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handlePreviousClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Previous
                                  </a>
                                </li>
                                {pageNumbers.map((pageNumber) => (
                                  <li
                                    key={pageNumber}
                                    className={`page-item ${
                                      pageNumber === currentPage ? "active" : ""
                                    }`}
                                  >
                                    <a
                                      className="page-link"
                                      onClick={() =>
                                        handlePaginationClick(pageNumber)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {pageNumber}
                                    </a>
                                  </li>
                                ))}
                                <li
                                  className={`page-item ${
                                    currentPage === pageNumbers.length
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handleNextClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Association;
