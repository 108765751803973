import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Table,
  Input,
  Label,
  NavItem,
  NavLink,
  Spinner,
  Row,
  Button,
  TabContent,
  TabPane,
} from "reactstrap";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

const AddAdvertisement = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [activeTab, setactiveTab] = useState(1);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [selectedfile, setSelectedFile] = useState();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");

    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  const requestDataSubmit = () => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    const isValidUrl = urlPattern.test(link);
    if (isValidUrl) {
      if (selectedfile && selectedfile.size > MAX_IMAGE_SIZE) {
        alert("Please upload image upto 100KB in size.");
        setSelectedFile();
        return;
      }
      setIsLoading(true);
      var myHeaders = new Headers();
      const jwt = localStorage.getItem("jwt");
      //console.log(localStorage.getItem("jwt"));
      myHeaders.append("x-api-key", jwt);

      const formData = new FormData();
      formData.append("description", description);
      formData.append("link", link);
      formData.append("coverImg", selectedfile);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      fetch(`${REACT_APP_API_ENDPOINT}/advertisment`, requestOptions).then(
        (result) => {
          result.json().then((resp) => {
            setIsLoading(false);
            if (result.status === 200) {
              setShowSuccessPopup(true); // Show the success popup
              setTimeout(() => {
                setShowSuccessPopup(false); // Hide the success popup after 3 seconds
              }, 500);
              // Other code...
              window.open("/edit-advertisement", "_self");
            } else {
              alert("Please fill required details!");
            }
          });
        }
      );
    } else {
      alert("Please enter valid url. Ex: https://www.xyz.com/");
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }

  const [preview, setPreview] = useState({ imgSrc: null });
  const setSelectedFileformat = (file) => {
    if (file !== undefined) {
      const promiseArr = [];
      for (let i = 0; i < file.length; i++) {
        if (file.size > 100 * 1024) {
          alert(`Image ${file.name} size cannot be more than 100Kb.`);
          break;
        }
        promiseArr.push(
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file[i]);
            reader.onload = async () => {
              resolve(reader.result);
            };
            reader.onerror = (e) => reject(e);
          })
        );
      }
      Promise.all(promiseArr).then((result) => {
        setPreview({
          imgSrc: [...result],
        });
      });
    }
  };
  return (
    <LoadingOverlay
      active={isLoading}
      spinner={
        <Spinner style={{ position: "absolute", left: "60%" }}></Spinner>
      }
    >
      <React.Fragment>
        <div className="page-content" style={{ padding: 0 }}>
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>
            <Col md={10}>
              <Container fluid={true}>
                <Header />
                <Row style={{ marginTop: 85 }}>
                  <Col lg={2}></Col>
                  <Col lg={8}>
                    <Card>
                      <CardBody>
                        <div className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem>
                                <NavLink className="h4">
                                  Upload an Advertisement
                                </NavLink>
                              </NavItem>
                            </ul>
                          </div>

                          <div className="content clearfix">
                            <div className="body">
                              <TabContent>
                                <TabPane>
                                  <Form>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label
                                          htmlFor="example-text-input"
                                          className="col-form-label"
                                        >
                                          Link*
                                        </Label>
                                        <div>
                                          <Input
                                            className="form-control"
                                            type="url"
                                            value={link}
                                            onChange={(e) =>
                                              setLink(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <div className="mb-3">
                                          <Label>
                                            Content of the advertisement*
                                          </Label>

                                          <Input
                                            type="textarea"
                                            id="textarea"
                                            value={description}
                                            onChange={(e) =>
                                              setDescription(e.target.value)
                                            }
                                            maxLength="225"
                                            rows="5"
                                            placeholder="This Description has a limit of 400 chars."
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mb-3">
                                        <Label htmlFor="resume">
                                          Cover Image / Logo of the company*
                                        </Label>
                                        <Input
                                          type="file"
                                          className="form-control"
                                          id="resume"
                                          // multiple
                                          onChange={(e) => {
                                            setSelectedFile(e.target.files[0]);
                                            setSelectedFileformat(
                                              e.target.files[0]
                                            );
                                          }}
                                        />
                                      </Col>

                                      <div className="table-responsive">
                                        <Table className="table mb-0">
                                          <thead>
                                            {preview.imgSrc?.map(
                                              (image, index) => (
                                                <tr key={index}>
                                                  <th>
                                                    <img
                                                      className="rounded avatar-lg"
                                                      alt=""
                                                      src={image}
                                                    />
                                                  </th>
                                                </tr>
                                              )
                                            )}
                                          </thead>
                                        </Table>
                                      </div>
                                    </Row>

                                    <Row className="text-center">
                                      <Col>
                                        <Button
                                          color="primary"
                                          onClick={() => requestDataSubmit()}
                                          className={`btn btn-primary waves-effect waves-light ${
                                            link &&
                                            description &&
                                            selectedfile !== undefined
                                              ? ""
                                              : "disabled"
                                          }`}
                                        >
                                          Post
                                        </Button>{" "}
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>
                              </TabContent>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={2}>{""}</Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
        {showSuccessPopup && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
              padding: 10,
              paddingHorizontal: 20,
              backgroundColor: "green",
              color: "white",
              borderRadius: 5,
              zIndex: 9999,
              width: 200, // Increase the width as per your requirement
              height: 100, // Increase the height as per your requirement
            }}
          >
            Success! Advertisement posted.
          </div>
        )}
      </React.Fragment>
    </LoadingOverlay>
  );
};

export default AddAdvertisement;
