import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  CardText,
  Container,
  Input,
  Spinner,
  Label,
  Modal,
  Form,
} from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
//Import Calander
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

// import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";

const EditTrending = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [trending, setTrending] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedfile, setSelectedFile] = useState([]);
  const [modalBackdrop, setModalBackdrop] = useState({});
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function togBackdrop(cmeId) {
    setModalBackdrop((prevBackdrop) => ({
      ...prevBackdrop,
      [cmeId]: !prevBackdrop[cmeId],
    }));
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const [preview, setPreview] = useState({ imgSrc: null });
  const setSelectedFileformat = (file) => {
    setSelectedFile(file);
    const promiseArr = [];
    for (let i = 0; i < file.length; i++) {
      if (file.size > 20 * 1024 * 1024) {
        alert(`Image ${file.name} size cannot be more than 20mb.`);
        break;
      }
      promiseArr.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = async () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
        })
      );
    }
    Promise.all(promiseArr).then((result) => {
      setPreview({
        imgSrc: [...result],
      });
    });
  };

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // For GET list of the CME
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    // Function to fetch events data from the server
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        fetch(
          `${REACT_APP_API_ENDPOINT}/trending/paginate?page=${currentPage}&pageSize=${pageSize}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((res) => {
            const { events, pagination } = res;

            setTotalPages(pagination.totalPages);
            // Map CME data to events array
            const mappedEvents = events.map((story) => ({
              _id: story._id,
              description: story.description,
              link: story.link,
            }));

            setTrending(mappedEvents);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, [currentPage, pageSize]);
  /*useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/trending`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Trending Result: ", result);

        // Map CME data to events array
        const mappedEvents = result.map((story) => ({
          _id: story._id,
          description: story.description,
          link: story.link,
        }));

        setTrending(mappedEvents);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);*/

  function changeTrending(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      trendingId: _id,
      description: description,
      link: link,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/trending`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(""))
      .catch((error) => console.log("error", error));
    window.open("/edit-trending", "_self");
  }

  function deleteTrending(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      trendingId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/trending`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => console.log(result))
      .catch((error) => console.error("Error:", error));

    window.open("/edit-trending", "_self");
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginLeft: 10,
                  marginTop: 85,
                }}
              >
                <h3>Edit Trending Videos</h3>
              </Row>
              <Row className="px-3">
                <Col md={12}>
                  <Row style={{ marginBottom: 10 }}>
                    {/* Pagination controls */}
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{ fontSize: 16 }}
                      >
                        <i className="uil uil-arrow-left"></i>
                        Prev
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {currentPage + "/" + totalPages}
                        </span>
                      </div>

                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        style={{ fontSize: 16 }}
                      >
                        Next
                        <i className="uil uil-arrow-right"></i>
                      </Button>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Button
                      color="primary"
                      style={{ position: "absolute", top: 200, left: "50%" }}
                      disabled
                    >
                      <Spinner size="sm">Loading...</Spinner>
                      <span> Loading</span>
                    </Button>
                  ) : (
                    <>
                      <Row>
                        {trending ? (
                          trending.map((trending, index) => (
                            <Col md={6} key={index}>
                              <Container>
                                <Card
                                  className="my-2"
                                  style={{ borderRadius: 15 }}
                                >
                                  <CardBody>
                                    <CardText>
                                      <Row className="my-2">
                                        <iframe
                                          width="100%"
                                          height="260"
                                          src={trending.link}
                                          title="video player"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                        ></iframe>
                                      </Row>

                                      <Row className="my-1">
                                        <Col md={3}>
                                          <h5>Video Link :</h5>
                                        </Col>
                                        <Col md={9}>
                                          <span>{trending.link}</span>
                                        </Col>
                                      </Row>

                                      <Row className="my-1">
                                        <Col md={3}>
                                          <h5>Description :</h5>
                                        </Col>
                                        <Col md={9}>
                                          <span>{trending.description}</span>
                                        </Col>
                                      </Row>
                                    </CardText>

                                    <Row className="my-1">
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            setLink(trending.link);
                                            setDescription(
                                              trending.description
                                            );
                                            setSelectedFile();
                                            togBackdrop(trending._id);
                                          }}
                                        >
                                          <i className="uil uil-edit"></i>
                                        </Button>
                                        <Modal
                                          isOpen={modalBackdrop[trending._id]} // Use the state variable to control the modal visibility
                                          toggle={() =>
                                            togBackdrop(trending._id)
                                          }
                                          scrollable={true}
                                          id="staticBackdrop"
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >
                                              Edit Story
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={() => {
                                                togBackdrop(trending._id);
                                              }}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <Form>
                                              <Row>
                                                <Col className="mb-3">
                                                  <label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Video Link*
                                                  </label>
                                                  <div>
                                                    <input
                                                      className="form-control"
                                                      type="url"
                                                      value={link}
                                                      onChange={(e) =>
                                                        setLink(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label>Description*</Label>

                                                  <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={description}
                                                    onChange={(e) =>
                                                      setDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    maxLength="225"
                                                    rows="5"
                                                    placeholder="This Description has a limit of 400 chars."
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label htmlFor="resume">
                                                    Cover Image
                                                  </Label>
                                                  <Input
                                                    type="file"
                                                    className="form-control"
                                                    id="resume"
                                                    onChange={(e) =>
                                                      setSelectedFileformat(
                                                        e.target.files
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <div className="table-responsive">
                                                  <Table className="table mb-0">
                                                    <thead>
                                                      {preview.imgSrc?.map(
                                                        (image, index) => (
                                                          <tr key={index}>
                                                            <th>
                                                              <img
                                                                className="rounded avatar-lg"
                                                                alt=""
                                                                src={image}
                                                              />
                                                            </th>
                                                          </tr>
                                                        )
                                                      )}
                                                    </thead>
                                                  </Table>
                                                </div>
                                              </Row>
                                            </Form>
                                          </div>
                                          <div className="modal-footer">
                                            <Button
                                              color="primary"
                                              className={`btn btn-primary ${
                                                link && description
                                                  ? ""
                                                  : "disabled"
                                              }`}
                                              onClick={() =>
                                                changeTrending(trending._id)
                                              }
                                            >
                                              Update
                                            </Button>
                                          </div>
                                        </Modal>
                                      </Col>
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",

                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() =>
                                            deleteTrending(trending._id)
                                          }
                                        >
                                          <i className="uil uil-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Container>
                            </Col>
                          ))
                        ) : (
                          <Col>
                            <h3>Nothing to show!</h3>
                          </Col>
                        )}
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        {/* Pagination controls */}
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            style={{ fontSize: 16 }}
                          >
                            <i className="uil uil-arrow-left"></i>
                            Prev
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: 16 }}>
                              {currentPage + "/" + totalPages}
                            </span>
                          </div>
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            style={{ fontSize: 16 }}
                          >
                            Next
                            <i className="uil uil-arrow-right"></i>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EditTrending;
