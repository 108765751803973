import React, { useState } from "react";
import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent";
import logoLight from "../../assets/images/WeDocLogo.png";

import logoLight1 from "../../assets/images/WeDocLogo.png";
import logo from "../../assets/images/WeDocLogo.png";
import "./Sidebar.css";

const Sidebar = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  return (
    <React.Fragment>
      <div
        className={`vertical-menu ${sidebarOpen ? "sidebar-enable" : ""}`}
        style={{ width: "16.66%" }}
      >
        <div className="navbar-brand-box" style={{ width: "16.66%" }}>
          <Link
            to="/dashboard"
            className="logo logo-dark side-bar-1"
            style={{ marginLeft: "20px" }}
          >
            <span className="logo-sm logo-side-1">
              <img src={logoLight} alt="" height="40" width="70" />
            </span>
            <span className="logo-lg side-logo-lg-1">
              <img src={logoLight1} alt="" height="100" width="100" />
            </span>
          </Link>

          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm logo-side-1">
              <img src={logoLight} alt="" height="40" width="70" />
            </span>
            <span className="logo-lg">
              <img src={logoLight1} alt="" height="95" width="180" />
            </span>
          </Link>
        </div>
        <button
          onClick={() => {
            tToggle();
          }}
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars"></i>
        </button>

        <div className="h-100 d-flex flex-column">
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
