import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// CME
import CMEList from "../pages/CME/CMEList";
import EditCME from "../pages/CME/EditCME";
// Events
import EventsList from "../pages/Events/EventsList";
import EditEvents from "../pages/Events/EditEvents";
import EditEventSummery from "../pages/Events/EditEventSummery";

// Gallery
import AddGallery from "../pages/Gallery/AddGallery";
import EditGallery from "../pages/Gallery/EditGallery";

// Featured Videos
import AddFeaturedVideos from "../pages/FeaturedVideos/AddFeaturedVideos";

// Career
import Career from "../pages/Career/Career";

// Top Stories
import AddTopStories from "../pages/TopStories/AddTopStories";

// Trending
import Trending from "../pages/Trending/Trending";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Advertisement
import AddAdvertisement from "../pages/Advertisement/AddAdvertisement";

// Reference
import Reference from "../pages/Reference/Reference";

// Membership
import Membership from "../pages/Membership/Membership";

// Doctors
import Doctors from "../pages/Doctors/Doctors";

// Association
import Association from "../pages/Association/Association";

// Pending Request
import PendingRequest from "../pages/PendingRequest/PendingRequest";

import EventSummery from "../pages/Events/EventSummery";
import CMESummery from "../pages/CME/CMESummery";
import MyProfile from "../pages/MyProfile/MyProfile";
import President from "../pages/President/President";
import Specialists from "../pages/Specialists/Specialists";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import EditCMESummery from "../pages/CME/EditCMESummery";
import EditCareer from "../pages/Career/EditCareer";
import EditTopStories from "../pages/TopStories/EditTopStories";
import EditTrending from "../pages/Trending/EditTrending";
import EditAdvertisement from "../pages/Advertisement/EditAdvertisement";

const userRoutes = [
  // yaha pe neeche wala list aayege jo meine change kare hai
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // yeah list upar aayege
  { path: "/dashboard", component: Dashboard },

  // Association
  { path: "/association", component: Association },

  // Career
  { path: "/career", component: Career },
  { path: "/edit-career", component: EditCareer },

  // Top Stories
  { path: "/add-top-stories", component: AddTopStories },
  { path: "/edit-top-stories", component: EditTopStories },

  // Featured videos
  { path: "/add-featured-videos", component: AddFeaturedVideos },

  // Trending
  { path: "/trending", component: Trending },
  { path: "/edit-trending", component: EditTrending },

  // Reference
  { path: "/reference", component: Reference },

  // Pending request
  { path: "/pending-request", component: PendingRequest },

  // Advertisement
  { path: "/add-advertisement", component: AddAdvertisement },
  { path: "/edit-advertisement", component: EditAdvertisement },

  // Membership
  { path: "/membership", component: Membership },

  // President
  { path: "/president", component: President },

  // Doctors
  { path: "/doctors", component: Doctors },

  // Specialistss
  { path: "/specialists", component: Specialists },

  // CME
  { path: "/cme-list", component: CMEList },
  { path: "/edit-cme-summery", component: EditCMESummery },
  { path: "/edit-cme", component: EditCME },
  { path: "/summery-cme", component: CMESummery },

  //Events
  { path: "/events-list", component: EventsList },
  { path: "/edit-event-summery", component: EditEventSummery },
  { path: "/edit-events", component: EditEvents },
  { path: "/summery-event", component: EventSummery },

  // Gallery
  { path: "/add-gallery", component: AddGallery },
  { path: "/edit-gallery", component: EditGallery },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // //profile
  { path: "/my-profile", component: MyProfile },

  //Privacy Policy
  { path: "/privacy-policy", component: PrivacyPolicy },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },

  // yaha tk hai list
];

export { userRoutes, authRoutes };
