import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  CardTitle,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import avatar4 from "../../assets/images/users/avatar-4.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import QRCode from "qrcode.react";

const Doctors = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_backdrop1, setmodal_backdrop1] = useState(false);
  const [doctor, setDoctor] = useState();
  const [association, setAssociation] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const MAX_PAGE_NUMBERS = 10;

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  const getAssociation = (arr, emailId) => {
    if (!arr) return "Default Name";
    //console.log(emailId);
    let asso = arr.find((_association) => _association._id === emailId);
    return asso && asso.name ? asso.name : "Default Name";
  };
  // Get doctor in the list
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/doctor?searchQuery=${searchQuery}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setSearchResults(result))
      .catch((error) => console.log("error", error));
  }, [searchQuery]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults
    ? searchResults.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil((doctor && doctor.length) / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  // Delete doctor from the list
  function deleteDoctor(_id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      doctorId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    //console.log(_id);
    fetch(`${REACT_APP_API_ENDPOINT}/doctor`, requestOptions).then((result) => {
      result.json().then((response) => {
        console.warn(response);
        window.open("/doctors", "_self");
      });
    });
  }

  // Association k dropdown  liye calling get api here
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [profile, setProfile] = useState();
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user`, requestOptions)
      .then((response) => response.json())
      .then((result) => setProfile(result))
      .catch((error) => console.log("error", error));
  }, []);
  const getUserByEmail = (email) => {
    if (!profile) return {};
    //console.log(profile);
    let u = profile.find((u) => u.email === email);
    return u;
  };
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/association`, requestOptions)
      .then((response) => response.json())
      .then((result) => setAssociation(result))
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/doctor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        setDoctor(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop1() {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const renderPageNumbers = () => {
    const totalPageNumbers = Math.ceil(
      (doctor && doctor.length) / itemsPerPage
    );

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_NUMBERS / 2));
    let endPage = Math.min(startPage + MAX_PAGE_NUMBERS - 1, totalPageNumbers);

    // Adjust the startPage if needed
    if (endPage - startPage + 1 < MAX_PAGE_NUMBERS) {
      startPage = Math.max(1, endPage - MAX_PAGE_NUMBERS + 1);
    }

    const pageNumbersToDisplay = [];

    for (let i = startPage; i <= endPage; i++) {
      pageNumbersToDisplay.push(i);
    }

    return pageNumbersToDisplay.map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
      >
        <a
          className="page-link"
          onClick={() => handlePaginationClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };
  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginTop: 85,
                }}
              >
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>
                        <h3>Doctors</h3>
                      </CardTitle>
                      {isLoading ? (
                        <Button
                          color="primary"
                          style={{
                            position: "absolute",
                            top: 200,
                            left: "50%",
                          }}
                          disabled
                        >
                          <Spinner size="sm">Loading...</Spinner>
                          <span> Loading</span>
                        </Button>
                      ) : (
                        <>
                          <Row>
                            <Col lg={2} className="mb-2">
                              <Button id="caret" color="primary">
                                Total No. of Doctors <br />
                                <h4 style={{ color: "#fff" }}>
                                  {searchResults && searchResults.length
                                    ? searchResults.length
                                    : 0}
                                </h4>
                              </Button>
                            </Col>
                            <Col lg={6}></Col>
                            <Col lg={4}>
                              <input
                                className="form-control"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search..."
                              />
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <table className="table table-bordered border-dark mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>Profile Photo</th>
                                  <th>Doctor Name</th>
                                  <th>Degree</th>
                                  <th>Association Name</th>
                                  <th>Designation</th>
                                  {/*<th>SuccessfulOT</th>*/}
                                  <th>Contact No.</th>
                                  <th>Membership</th>
                                  <th>Status</th>
                                  <th>QR Code</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems &&
                                  currentItems.map((doctor, id) => (
                                    <tr key={id}>
                                      <th scope="row">
                                        {(currentPage - 1) * itemsPerPage +
                                          (id + 1)}
                                      </th>

                                      <td>
                                        <img
                                          src={
                                            doctor.profileImage
                                              ? `data:image/png;base64,${doctor.profileImage}`
                                              : avatar4
                                          }
                                          className="rounded avatar-md"
                                          alt=""
                                        />
                                      </td>
                                      <td> {doctor ? doctor.name : null}</td>
                                      <td>{doctor ? doctor.degree : null}</td>
                                      <td>
                                        {doctor
                                          ? getAssociation(
                                              association,
                                              doctor.association
                                            )
                                          : null}
                                      </td>
                                      <td>
                                        {doctor ? doctor.designation : null}
                                      </td>
                                      {/*<td>
                                        {doctor ? doctor.successfulOT : null}+
                                          </td>*/}
                                      <td>{doctor ? doctor.contact : null}</td>

                                      <td>
                                        {doctor.plan ? doctor.plan.name : null}
                                      </td>
                                      <td>
                                        {doctor.plan
                                          ? doctor.plan.status
                                          : null}
                                      </td>
                                      <td>
                                        <QRCode
                                          value={JSON.stringify({
                                            ...doctor,
                                            profileImage: "",
                                          })}
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          color="danger"
                                          className="btn btn-danger waves-effect waves-light"
                                          onClick={() =>
                                            deleteDoctor(doctor && doctor._id)
                                          }
                                        >
                                          Remove
                                        </Button>{" "}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-center">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className={`page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handlePreviousClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Previous
                                  </a>
                                </li>
                                {renderPageNumbers()}
                                <li
                                  className={`page-item ${
                                    currentPage === pageNumbers.length
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handleNextClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Doctors;
