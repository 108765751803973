import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import {
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Spinner,
} from "reactstrap";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
import QRCode from "qrcode.react";

const President = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [drp_danger1, setDrp_danger1] = useState(false);
  const [president, setPresident] = useState([]);
  const [association, setAssociation] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchAssociation, setSearchAssociation] = useState("");
  const [filteredPresident, setFilteredPresident] = useState([]);
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const MAX_PAGE_NUMBERS = 10;

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };
  const getAssociation = (arr, emailId) => {
    let asso = arr.find((_association) => _association.emailId === emailId);
    return asso && asso.name ? asso.name : "Default Name";
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPresident.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredPresident.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/user`, requestOptions)
      .then((response) => response.json())
      .then((result) => setProfile(result))
      .catch((error) => console.log("error", error));
  }, []);
  const getUserByEmail = (email) => {
    if (!profile) return {};
    //console.log(profile);
    let u = profile.find((u) => u.email === email);
    return u;
  };
  useEffect(() => {
    const fetchPresident = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}/president`, {
          method: "GET",
          headers: {
            "x-api-key": localStorage.getItem("jwt"),
          },
        });
        const result = await response.json();
        setIsLoading(false);
        setPresident(result);
        setFilteredPresident(result);
      } catch (error) {
        console.log("error", error);
      }
    };
    setIsLoading(true);
    fetchPresident();
  }, []);

  useEffect(() => {
    const fetchAssociation = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}/association`, {
          method: "GET",
          headers: {
            "x-api-key": localStorage.getItem("jwt"),
          },
        });
        const result = await response.json();
        setAssociation(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchAssociation();
  }, []);

  useEffect(() => {
    const filteredResults = president.filter((item) => {
      const nameMatch =
        searchName.trim() === "" ||
        item.name.toLowerCase().includes(searchName.toLowerCase());
      const emailMatch =
        searchEmail.trim() === "" ||
        item.email.toLowerCase().includes(searchEmail.toLowerCase());
      const associationMatch =
        searchAssociation.trim() === "" ||
        getAssociation(association, item.email)
          .toLowerCase()
          .includes(searchAssociation.toLowerCase());
      return nameMatch && emailMatch && associationMatch;
    });

    setFilteredPresident(filteredResults);
  }, [searchName, searchEmail, searchAssociation, president, association]);

  const renderPageNumbers = () => {
    const totalPageNumbers = Math.ceil(
      (filteredPresident && filteredPresident.length) / itemsPerPage
    );

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_NUMBERS / 2));
    let endPage = Math.min(startPage + MAX_PAGE_NUMBERS - 1, totalPageNumbers);

    // Adjust the startPage if needed
    if (endPage - startPage + 1 < MAX_PAGE_NUMBERS) {
      startPage = Math.max(1, endPage - MAX_PAGE_NUMBERS + 1);
    }

    const pageNumbersToDisplay = [];

    for (let i = startPage; i <= endPage; i++) {
      pageNumbersToDisplay.push(i);
    }

    return pageNumbersToDisplay.map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
      >
        <a
          className="page-link"
          onClick={() => handlePaginationClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row style={{ marginTop: 85 }}>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>
                        <h3>President</h3>
                      </CardTitle>
                      {isLoading ? (
                        <Button
                          color="primary"
                          style={{
                            position: "absolute",
                            top: 200,
                            left: "50%",
                          }}
                          disabled
                        >
                          <Spinner size="sm">Loading...</Spinner>
                          <span> Loading</span>
                        </Button>
                      ) : (
                        <>
                          <Row>
                            <Col lg={3} md={3} className="mb-2">
                              <Button id="caret" color="primary">
                                Total No. of President <br />
                                <h4 style={{ color: "#fff" }}>
                                  {filteredPresident.length}
                                </h4>
                              </Button>
                            </Col>
                            <Col lg={3} md={3}>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  placeholder="Search by Name"
                                  value={searchName}
                                  onChange={(e) =>
                                    setSearchName(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={3} md={3}>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  placeholder="Search by Email"
                                  value={searchEmail}
                                  onChange={(e) =>
                                    setSearchEmail(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={3} md={3}>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  placeholder="Search by Association"
                                  value={searchAssociation}
                                  onChange={(e) =>
                                    setSearchAssociation(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="table-responsive">
                            <table className="table table-bordered border-dark mb-0 text-center">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>Profile Photo</th>
                                  <th>President Name</th>
                                  <th>Association Name</th>
                                  <th>President Email</th>
                                  <th>QR Code</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems.map((index, id) => (
                                  <tr key={id}>
                                    <th scope="row">
                                      {(currentPage - 1) * itemsPerPage +
                                        id +
                                        1}
                                    </th>
                                    <td>
                                      <img
                                        src={
                                          index.coverImg
                                            ? `data:image/png;base64,${index.coverImg}`
                                            : avatar4
                                        }
                                        className="rounded avatar-md"
                                        alt={`${index.name} president`}
                                      />
                                    </td>
                                    <td>{index ? index.name : null}</td>
                                    <td>
                                      {index
                                        ? getAssociation(
                                            association,
                                            index.email
                                          )
                                        : null}
                                    </td>
                                    <td>{index ? index.email : null}</td>
                                    <td>
                                      <QRCode
                                        value={JSON.stringify({
                                          name: index.name,
                                          email: index.email,
                                          _id: index._id,
                                          __v: index.__v,
                                        })}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* Pagination */}
                            <div className="d-flex justify-content-center">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className={`page-item ${
                                      currentPage === 1 ? "disabled" : ""
                                    }`}
                                  >
                                    <a
                                      className="page-link"
                                      onClick={handlePreviousClick}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {renderPageNumbers()}
                                  <li
                                    className={`page-item ${
                                      currentPage === pageNumbers.length
                                        ? "disabled"
                                        : ""
                                    }`}
                                  >
                                    <a
                                      className="page-link"
                                      onClick={handleNextClick}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default President;
