import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  CardText,
  Container,
  Input,
  Label,
  Modal,
  Form,
  CardImg,
} from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
//Import Calander
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

// import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";

const EditTopStories = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalBackdrop, setModalBackdrop] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [selectedfile, setSelectedFile] = useState();
  const [preview, setPreview] = useState({ imgSrc: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const history = useHistory();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function togBackdrop(cmeId) {
    setModalBackdrop((prevBackdrop) => ({
      ...prevBackdrop,
      [cmeId]: !prevBackdrop[cmeId],
    }));
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const setSelectedFileformat = (file) => {
    setSelectedFile(file);
    const promiseArr = [];
    for (let i = 0; i < file.length; i++) {
      if (file.size > 100 * 1024) {
        alert(`Image ${file.name} size cannot be more than 100Kb.`);
        break;
      }
      promiseArr.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = async () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
        })
      );
    }
    Promise.all(promiseArr).then((result) => {
      setPreview({
        ...preview,
        imgSrc: preview.imgSrc ? [...preview.imgSrc, ...result] : [...result],
      });
    });
  };

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // For GET list of the stories
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    // Function to fetch events data from the server
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        fetch(
          `${REACT_APP_API_ENDPOINT}/stories/paginate?page=${currentPage}&pageSize=${pageSize}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((res) => {
            const { events, pagination } = res;

            setTotalPages(pagination.totalPages);
            // Map CME data to events array
            const mappedEvents = events.map((story) => ({
              _id: story._id,
              description: story.description,
              link: story.link,
              title: story.title,
              coverImg: story.coverImg,
            }));

            setStories(mappedEvents);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, [currentPage, pageSize]);

  /*useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/stories`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log("Stories Result: ", result);

        // Map CME data to events array
        const mappedEvents = result.map((story) => ({
          _id: story._id,
          description: story.description,
          link: story.link,
          title: story.title,
        }));
        setIsLoading(false);
        setStories(mappedEvents);
      })
      .catch((error) => console.log("error", error));
  }, []);*/

  function changeStory(_id) {
    if (selectedfile && selectedfile.size > MAX_IMAGE_SIZE) {
      alert("Please upload image upto 100KB in size.");
      setSelectedFile();
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("link", link);
    formData.append("coverImg", selectedfile);
    formData.append("storyId", _id);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/stories`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(""))
      .catch((error) => console.log("error", error));
    window.open("/edit-top-stories", "_self");
  }

  function deleteStory(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      storyId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/stories`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => console.log(result))
      .catch((error) => console.error("Error:", error));

    window.open("/edit-top-stories", "_self");
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginLeft: 10,
                  marginTop: 85,
                }}
              >
                <h3>Edit Stories</h3>
              </Row>

              <Row className="px-3">
                <Col md={12}>
                  <Row style={{ marginBottom: 10 }}>
                    {/* Pagination controls */}
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{ fontSize: 16 }}
                      >
                        <i className="uil uil-arrow-left"></i>
                        Prev
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {currentPage + "/" + totalPages}
                        </span>
                      </div>

                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        style={{ fontSize: 16 }}
                      >
                        Next
                        <i className="uil uil-arrow-right"></i>
                      </Button>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Button
                      color="primary"
                      style={{ position: "absolute", top: 200, left: "50%" }}
                      disabled
                    >
                      <Spinner size="sm">Loading...</Spinner>
                      <span> Loading</span>
                    </Button>
                  ) : (
                    <>
                      <Row>
                        {stories ? (
                          stories.map((story, index) => (
                            <Col md={6} key={index}>
                              <Container>
                                <Card
                                  className="my-2"
                                  style={{ borderRadius: 15 }}
                                >
                                  <CardBody>
                                    <CardTitle className="h4 mt-0">
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <CardImg
                                          className="img-fluid"
                                          src={`data:image/png;base64,${story.coverImg}`}
                                          style={{
                                            height: 100,
                                            objectFit: "contain",
                                          }}
                                          alt="Event banner"
                                        />
                                      </div>
                                    </CardTitle>
                                    <CardText>
                                      <Row className="my-1">
                                        <Col md={3}>
                                          <h5>Story Title :</h5>
                                        </Col>
                                        <Col md={9}>
                                          <span>{story.title}</span>
                                        </Col>
                                      </Row>

                                      <Row className="my-1">
                                        <Col md={3}>
                                          <h5>Story Link :</h5>
                                        </Col>
                                        <Col md={9}>
                                          <span>{story.link}</span>
                                        </Col>
                                      </Row>

                                      <Row className="my-1">
                                        <Col md={3}>
                                          <h5>Description :</h5>
                                        </Col>
                                        <Col md={9}>
                                          <span>{story.description}</span>
                                        </Col>
                                      </Row>
                                    </CardText>

                                    <Row className="my-1">
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            setTitle(story.title);
                                            setDescription(story.description);
                                            setLink(story.link);
                                            togBackdrop(story._id);
                                          }}
                                        >
                                          <i className="uil uil-edit"></i>
                                        </Button>
                                        <Modal
                                          isOpen={modalBackdrop[story._id]} // Use the state variable to control the modal visibility
                                          toggle={() => togBackdrop(story._id)}
                                          scrollable={true}
                                          id="staticBackdrop"
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >
                                              Edit Story
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={() => {
                                                togBackdrop(story._id);
                                              }}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <Form>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label>Story Title*</Label>

                                                  <Input
                                                    type="text"
                                                    id="textarea"
                                                    value={title}
                                                    onChange={(e) =>
                                                      setTitle(e.target.value)
                                                    }
                                                    maxLength="125"
                                                    rows="1"
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label>Description*</Label>

                                                  <Input
                                                    type="textarea"
                                                    id="textarea"
                                                    value={description}
                                                    onChange={(e) =>
                                                      setDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    maxLength="225"
                                                    rows="5"
                                                    placeholder="This Description has a limit of 400 chars."
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Video Link*
                                                  </Label>

                                                  <Input
                                                    className="form-control"
                                                    type="url"
                                                    value={link}
                                                    onChange={(e) =>
                                                      setLink(e.target.value)
                                                    }
                                                    defaultValue="https://ads.google.com"
                                                  />
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="mb-3">
                                                    <Label htmlFor="resume">
                                                      Cover Image*
                                                    </Label>
                                                    <Input
                                                      type="file"
                                                      className="form-control"
                                                      id="resume"
                                                      onChange={(e) =>
                                                        setSelectedFileformat(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          </div>
                                          <div className="modal-footer">
                                            <Button
                                              color="primary"
                                              className={`btn btn-primary ${
                                                title &&
                                                description &&
                                                link &&
                                                selectedfile !== undefined
                                                  ? ""
                                                  : "disabled"
                                              }`}
                                              onClick={() =>
                                                changeStory(story._id)
                                              }
                                            >
                                              Update
                                            </Button>
                                          </div>
                                        </Modal>
                                      </Col>
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light  m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",

                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() => deleteStory(story._id)}
                                        >
                                          <i className="uil uil-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Container>
                            </Col>
                          ))
                        ) : (
                          <Col>
                            <h3>Nothing to show!</h3>
                          </Col>
                        )}
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        {/* Pagination controls */}
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            style={{ fontSize: 16 }}
                          >
                            <i className="uil uil-arrow-left"></i>
                            Prev
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: 16 }}>
                              {currentPage + "/" + totalPages}
                            </span>
                          </div>
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            style={{ fontSize: 16 }}
                          >
                            Next
                            <i className="uil uil-arrow-right"></i>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EditTopStories;
