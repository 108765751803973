import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  CardText,
  Container,
  Input,
  Label,
  Modal,
  CardImg,
  Table,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useHistory } from "react-router-dom";
//Import Calander
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

// import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";

const EditCareer = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [career, setCareer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalBackdrop, setModalBackdrop] = useState({});
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [workplaceType, setWorkplaceType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState("");
  const [role, setRole] = useState("");
  const [logo, setLogo] = useState();
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function togBackdrop(cmeId) {
    setModalBackdrop((prevBackdrop) => ({
      ...prevBackdrop,
      [cmeId]: !prevBackdrop[cmeId],
    }));
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // For GET list of the CME
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    // Function to fetch events data from the server
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        fetch(
          `${REACT_APP_API_ENDPOINT}/career/paginate?page=${currentPage}&pageSize=${pageSize}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((res) => {
            const { events, pagination } = res;
            console.log(events);
            setTotalPages(pagination.totalPages);
            // Map CME data to events array
            const mappedEvents = events.map((career) => ({
              _id: career._id,
              company: career.company,
              location: career.location,
              jobTitle: career.jobTitle,
              workplaceType: career.workplaceType,
              jobType: career.jobType,
              description: career.description,
              role: career.role,
              email: career.email,
              experience: career.experience,
              logo: career.logo,
            }));

            setCareer(mappedEvents);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, [currentPage, pageSize]);

  /*useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/career`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("Career Result: ", result);

        // Map CME data to events array
        const mappedEvents = result.map((career) => ({
          _id: career._id,
          company: career.company,
          location: career.location,
          jobTitle: career.jobTitle,
          workplaceType: career.workplaceType,
          jobType: career.jobType,
          description: career.description,
        }));
        setIsLoading(false);
        setCareer(mappedEvents);
      })
      .catch((error) => console.log("error", error));
  }, []);*/

  // For Update the event list (PUT)
  function changeCareer(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      careerId: _id,
      company: company,
      location: location,
      jobTitle: jobTitle,
      workplaceType: workplaceType,
      jobType: jobType,
      description: description,
      role: role,
      email: email,
      experience: experience,
      logo: logo,
    });
    console.log("raw:", raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/career`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(""))
      .catch((error) => console.log("error", error));
    window.open("/edit-career", "_self");
  }

  const [preview, setPreview] = useState({ imgSrc: null });
  const setSelectedFileformat = (file) => {
    //setSelectedFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      // Convert image to base64 and set the state
      let data = reader.result;
      setLogo(data.split(",")[1]);

      //console.log(reader.result);
    };

    // Read the image as a data URL
    reader.readAsDataURL(file[0]);

    const promiseArr = [];
    for (let i = 0; i < file.length; i++) {
      if (file.size > 100 * 1024) {
        alert(`Image ${file.name} size cannot be more than 100KB.`);
        break;
      }
      promiseArr.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = async () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
        })
      );
    }
    Promise.all(promiseArr).then((result) => {
      setPreview({
        imgSrc: [...result],
      });
    });
  };

  function deleteCareer(_id) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      careerId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/career`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => console.log(result))
      .catch((error) => console.error("Error:", error));

    window.open("/edit-career", "_self");
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginLeft: 10,
                  marginTop: 85,
                }}
              >
                <h3>Edit Career</h3>
              </Row>

              <Row className="px-3">
                <Col md={12}>
                  <Row style={{ marginBottom: 10 }}>
                    {/* Pagination controls */}
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{ fontSize: 16 }}
                      >
                        <i className="uil uil-arrow-left"></i>
                        Prev
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {currentPage + "/" + totalPages}
                        </span>
                      </div>

                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        style={{ fontSize: 16 }}
                      >
                        Next
                        <i className="uil uil-arrow-right"></i>
                      </Button>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Button
                      color="primary"
                      style={{ position: "absolute", top: 200, left: "50%" }}
                      disabled
                    >
                      <Spinner size="sm">Loading...</Spinner>
                      <span> Loading</span>
                    </Button>
                  ) : (
                    <>
                      <Row>
                        {career && career.length > 0 ? (
                          career.map((job, index) => (
                            <Col md={6} key={index}>
                              <Container>
                                <Card
                                  className="m-2"
                                  style={{ borderRadius: 15 }}
                                >
                                  <CardBody>
                                    <CardTitle>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <CardImg
                                          className="img-fluid"
                                          src={`data:image/png;base64,${job.logo}`}
                                          style={{
                                            height: 100,
                                            objectFit: "contain",
                                          }}
                                          alt="logo"
                                        />
                                      </div>
                                    </CardTitle>
                                    <Row>
                                      <Col md={7}>
                                        <CardText>
                                          <b>Job Title:</b> {job.jobTitle}
                                        </CardText>
                                      </Col>
                                      <Col md={5}>
                                        <CardText>
                                          <b>Role:</b> {job.role}
                                        </CardText>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={7}>
                                        <CardText>
                                          <b>Company Name:</b> {job.company}
                                        </CardText>
                                      </Col>
                                      <Col md={5}>
                                        <CardText>
                                          <b>Location:</b> {job.location}
                                        </CardText>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={7}>
                                        <CardText>
                                          <b>Workplace Type:</b>{" "}
                                          {job.workplaceType}
                                        </CardText>
                                      </Col>
                                      <Col md={5}>
                                        <CardText>
                                          <b>Job Type:</b> {job.jobType}
                                        </CardText>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <CardText>
                                          <b>Experience:</b>{" "}
                                          {job.experience ? job.experience : 0}{" "}
                                          Year's
                                        </CardText>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <CardText>
                                          <b>Description:</b>
                                          <br></br>
                                          <span>{job.description}</span>
                                        </CardText>
                                      </Col>
                                    </Row>
                                    <Row className="m-1">
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() => {
                                            //setCareer(job.career);
                                            setCompany(job.company);
                                            setDescription(job.description);
                                            setJobTitle(job.jobTitle);
                                            setJobType(job.jobType);
                                            setLocation(job.location);
                                            setWorkplaceType(job.workplaceType);
                                            setRole(job.role);
                                            setEmail(job.email);
                                            setExperience(job.experience);
                                            togBackdrop(job._id);
                                          }}
                                        >
                                          <i className="uil uil-edit"></i>
                                        </Button>
                                        <Modal
                                          isOpen={modalBackdrop[job._id]} // Use the state variable to control the modal visibility
                                          toggle={() => togBackdrop(job._id)}
                                          scrollable={true}
                                          id="staticBackdrop"
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >
                                              Edit Job
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={() => {
                                                togBackdrop(job._id);
                                              }}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <Form>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Job Title*
                                                  </Label>
                                                  <div>
                                                    <Input
                                                      className="form-control"
                                                      type="text"
                                                      value={jobTitle}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setJobTitle(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Role*
                                                  </Label>
                                                  <div>
                                                    <Input
                                                      className="form-control"
                                                      type="text"
                                                      value={role}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setRole(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Company Name*
                                                  </Label>
                                                  <div>
                                                    <Input
                                                      className="form-control"
                                                      type="text"
                                                      value={company}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setCompany(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label htmlFor="resume">
                                                    Logo
                                                  </Label>
                                                  <Input
                                                    type="file"
                                                    className="form-control"
                                                    id="resume"
                                                    onChange={(e) =>
                                                      setSelectedFileformat(
                                                        e.target.files
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <div className="table-responsive">
                                                  <Table className="table mb-0">
                                                    <thead>
                                                      {preview.imgSrc?.map(
                                                        (image, index) => (
                                                          <tr key={index}>
                                                            <th>
                                                              <img
                                                                className="rounded avatar-lg"
                                                                alt=""
                                                                src={image}
                                                              />
                                                            </th>
                                                          </tr>
                                                        )
                                                      )}
                                                    </thead>
                                                  </Table>
                                                </div>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Email*
                                                  </Label>
                                                  <div>
                                                    <Input
                                                      className="form-control"
                                                      type="text"
                                                      value={email}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setEmail(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="mb-3">
                                                    <Label>
                                                      Workplace type*
                                                    </Label>
                                                    <Form.Select
                                                      aria-label="Default select example"
                                                      className="form-control"
                                                      value={workplaceType}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setWorkplaceType(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option
                                                        value="On-Site"
                                                        style={{
                                                          fontSize: 16,
                                                          marginTop: 4,
                                                        }}
                                                      >
                                                        On-Site
                                                      </option>
                                                      <option
                                                        value="Hybrid"
                                                        style={{
                                                          fontSize: 16,
                                                          marginTop: 4,
                                                        }}
                                                      >
                                                        Hybrid
                                                      </option>
                                                      <option
                                                        value="Remote"
                                                        style={{
                                                          fontSize: 16,
                                                          marginTop: 4,
                                                        }}
                                                      >
                                                        Remote
                                                      </option>
                                                    </Form.Select>
                                                    <Form.Text className="text-muted">
                                                      Please select the type of
                                                      workplace.
                                                    </Form.Text>
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row className="mb-3">
                                                <Col md={6}>
                                                  <div className="col-md-10">
                                                    <Label>Job Location*</Label>

                                                    <Input
                                                      type="text"
                                                      id="textarea"
                                                      value={location}
                                                      onChange={(e) =>
                                                        setLocation(
                                                          e.target.value
                                                        )
                                                      }
                                                      maxLength="125"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col md={6}>
                                                  <Label>Job type*</Label>
                                                  <Form.Select
                                                    aria-label="Default select example"
                                                    className="form-control"
                                                    value={jobType}
                                                    onChange={(e) =>
                                                      setJobType(e.target.value)
                                                    }
                                                    required={true}
                                                  >
                                                    <option>Part_Time</option>
                                                    <option>Full_Time</option>
                                                    <option>Contract</option>
                                                    <option>Temporary</option>
                                                    <option>Other</option>
                                                    <option>Volunteer</option>
                                                    <option>Internship</option>
                                                  </Form.Select>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label
                                                    htmlFor="example-text-input"
                                                    className="col-form-label"
                                                  >
                                                    Experience(in years)*
                                                  </Label>
                                                  <div>
                                                    <Input
                                                      className="form-control"
                                                      type="text"
                                                      value={experience}
                                                      required={true}
                                                      onChange={(e) =>
                                                        setExperience(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="mb-3">
                                                  <Label>Description*</Label>

                                                  <Input
                                                    type="textarea"
                                                    id="textarea"
                                                    value={description}
                                                    onChange={(e) =>
                                                      setDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    maxLength="1000"
                                                    rows="10"
                                                    placeholder="Please enter your text"
                                                  />
                                                </Col>
                                              </Row>
                                            </Form>
                                          </div>
                                          <div className="modal-footer">
                                            <Button
                                              color="primary"
                                              className={`btn btn-primary ${
                                                jobTitle &&
                                                company &&
                                                description &&
                                                workplaceType &&
                                                jobType &&
                                                location &&
                                                role &&
                                                email &&
                                                experience
                                                  ? ""
                                                  : "disabled"
                                              }`}
                                              onClick={() =>
                                                changeCareer(job._id)
                                              }
                                            >
                                              Update
                                            </Button>
                                          </div>
                                        </Modal>
                                      </Col>
                                      <Col
                                        xs={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",

                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          onClick={() => deleteCareer(job._id)}
                                        >
                                          <i className="uil uil-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Container>
                            </Col>
                          ))
                        ) : (
                          <h3>Nothing to show!</h3>
                        )}
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        {/* Pagination controls */}
                        <Col
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            style={{ fontSize: 16 }}
                          >
                            <i className="uil uil-arrow-left"></i>
                            Prev
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: 16 }}>
                              {currentPage + "/" + totalPages}
                            </span>
                          </div>
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            style={{ fontSize: 16 }}
                          >
                            Next
                            <i className="uil uil-arrow-right"></i>
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EditCareer;
