import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  Input,
  Label,
  Form,
  Modal,
  Container,
  Button,
  CardImg,
} from "reactstrap";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";
//Import Calander
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import images
import { Link } from "react-router-dom";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

const EditGallery = (props) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [preview, setPreview] = useState({ imgSrc: null });
  const [modal_backdrop1, setmodal_backdrop1] = useState({});
  const [gallery, setGallery] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventId, setEventId] = useState();
  const [image, setImage] = useState();
  const [video, setVideo] = useState(null);
  const [images, setImages] = useState([]);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const MAX_IMAGE_SIZE = 100 * 1024; // 100KB
  const MAX_VIDEO_SIZE = 10 * 1024 * 1024; // 10MB
  const history = useHistory();

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // For GET the Gallery list
  var myHeaders = new Headers();
  const jwt = localStorage.getItem("jwt");
  //console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    // Function to fetch events data from the server
    const fetchEvents = async () => {
      try {
        fetch(
          `${REACT_APP_API_ENDPOINT}/gallery/paginate?page=${currentPage}&pageSize=${pageSize}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((res) => {
            const { events, pagination } = res;
            const formattedGallery = events.map((item) => ({
              ...item,
              _id: item._id,
              images: item.coverImg, // Initially set as an empty array
              multipleImages: item.images ? [...item.images] : [],
              videos: [], // Initially set as an empty array
            }));
            setGallery(formattedGallery);
            setTotalPages(pagination.totalPages);
          });
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    // Call the fetchEvents function
    fetchEvents();
  }, [currentPage, pageSize]);

  /*useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/gallery`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Add images and videos properties to each gallery item
        const formattedGallery = result.map((item) => ({
          ...item,
          _id: item._id,
          images: [], // Initially set as an empty array
          videos: [], // Initially set as an empty array
        }));
        setIsLoading(false);
        setGallery(formattedGallery);
      })
      .catch((error) => console.log("error", error));
  }, []);*/

  // For GET the event name
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_ENDPOINT}/event`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEvents(result);
        // Map events to FullCalendar compatible format
        const formattedEvents = result.map((event) => {
          return {
            title: event.name,
            start: event.startDateTime,
            end: event.lastDateTime,
          };
        });
        setFormattedEvents(formattedEvents);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  // For Deleting the Gallery

  function deleteGallery(_id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", jwt);
    var raw = JSON.stringify({
      galleryId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    //console.log(_id);
    fetch(`${REACT_APP_API_ENDPOINT}/gallery`, requestOptions).then(
      (result) => {
        result.json().then((response) => {
          //console.warn(response);
          window.open("/edit-gallery", "_self");
        });
      }
    );
  }

  // For Update the gallery list (PUT)
  function changeGallery(_id) {
    if (image && image.size > MAX_IMAGE_SIZE) {
      alert("Please upload image upto 100KB in size.");
      setImage();
      return;
    }
    if (images.length < 2) {
      alert("Please upload minimum 2 images.");
      setImages();
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", jwt);

    // Find the gallery item with the specified _id
    const updatedGalleryItem = gallery.find((item) => item._id === _id);

    const formData = new FormData();
    formData.append("event", eventId);
    formData.append("coverImg", image);
    // Handle images array
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    formData.append("video", video);
    formData.append("id", _id);

    // var raw = JSON.stringify({
    //   eventName: eventName,
    //   images: images, // Set the updated images here
    //   videos: video ? [video] : [], // Set the updated video here
    // });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(`${REACT_APP_API_ENDPOINT}/gallery`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);

        // Update the images and videos properties of the gallery item
        updatedGalleryItem.images = images; // Set the updated images here as well
        updatedGalleryItem.videos = video ? [video] : []; // Set the updated video here as well

        // Update the gallery state with the modified gallery item
        setGallery([...gallery]);
        window.open("/edit-gallery", "_self");
      })
      .catch((error) => alert("error", error));
  }

  function tog_backdrop1(cmeId) {
    setmodal_backdrop1((prevBackdrop) => ({
      ...prevBackdrop,
      [cmeId]: !prevBackdrop[cmeId],
    }));
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Container fluid={true}>
              <Header />
              <Row
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 85,
                }}
              >
                <Col sm={6}>
                  <h3>Edit Gallery</h3>
                </Col>
                <Col sm={6}>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="search-box ml-2">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-white border-light"
                            style={{ borderRadius: 15 }}
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <i className="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>

              <Row className="p-3">
                <Col mg={12} xl={12}>
                  <Row style={{ marginBottom: 10 }}>
                    {/* Pagination controls */}
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{ fontSize: 16 }}
                      >
                        <i className="uil uil-arrow-left"></i>
                        Prev
                      </Button>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {currentPage + "/" + totalPages}
                        </span>
                      </div>

                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        style={{ fontSize: 16 }}
                      >
                        Next
                        <i className="uil uil-arrow-right"></i>
                      </Button>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Button
                      color="primary"
                      style={{ position: "absolute", top: 200, left: "50%" }}
                      disabled
                    >
                      <Spinner size="sm">Loading...</Spinner>
                      <span> Loading</span>
                    </Button>
                  ) : (
                    <>
                      <Row>
                        {gallery &&
                          gallery.map((index, id) => {
                            const event =
                              events &&
                              events.find((event) => event._id === index.event);
                            // Apply search filter
                            if (
                              searchQuery &&
                              event &&
                              !event.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ) {
                              return null;
                            }
                            return (
                              <Col md={4} xl={3} key={id}>
                                <Card
                                  style={{
                                    borderRadius: 15,
                                  }}
                                >
                                  <CardBody>
                                    <CardTitle className="h4 mt-0">
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <CardImg
                                          className="img-fluid"
                                          src={`data:image/png;base64,${index.images}`}
                                          style={{
                                            height: 100,
                                            objectFit: "contain",
                                          }}
                                          alt="Event banner"
                                        />
                                      </div>
                                    </CardTitle>
                                    <CardSubtitle className="h5 mt-3">
                                      {event && event.name}
                                    </CardSubtitle>

                                    <CardSubtitle
                                      style={{
                                        marginTop: 1,
                                      }}
                                    >
                                      {events &&
                                        events.find(
                                          (event) => event._id === index.event
                                        )?.location}
                                    </CardSubtitle>

                                    <CardSubtitle className="mt-3">
                                      <Link
                                        to="#"
                                        className="card-link text-dark float-md-start"
                                      >
                                        {index.videos.length}{" "}
                                        {index.videos.length === 1
                                          ? "Video"
                                          : "Videos"}
                                      </Link>
                                    </CardSubtitle>
                                    {index.multipleImages.length > 0 && (
                                      <CardSubtitle className="mt-3">
                                        <Link
                                          to="#"
                                          className="card-link text-dark float-md-end"
                                        >
                                          {index.multipleImages.length}{" "}
                                          {index.multipleImages.length === 1
                                            ? "Photo"
                                            : "Photos"}
                                        </Link>
                                      </CardSubtitle>
                                    )}

                                    <br />
                                    <Row>
                                      <Col
                                        md={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          onClick={() => {
                                            //console.warn(index);
                                            setEventName(event.name);
                                            setEventId(event._id);
                                            tog_backdrop1(index._id);
                                          }}
                                          data-toggle="modal"
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <i className="uil uil-edit"></i>
                                        </Button>
                                        <Modal
                                          isOpen={modal_backdrop1[index._id]}
                                          toggle={() => {
                                            tog_backdrop1(index._id);
                                          }}
                                          scrollable={true}
                                          id="staticBackdrop"
                                        >
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="staticBackdropLabel"
                                            >
                                              Edit gallery
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              onClick={() => {
                                                setmodal_backdrop1(false);
                                              }}
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <Form>
                                              <Row>
                                                <Col>
                                                  <div className="mb-3">
                                                    <Label>Event Name*</Label>
                                                    <Input
                                                      type="select"
                                                      value={eventId}
                                                      required
                                                      onChange={(e) => {
                                                        const selectedEventId =
                                                          e.target.value;
                                                        const selectedEvent =
                                                          events.find(
                                                            (event) =>
                                                              event._id ===
                                                              selectedEventId
                                                          );
                                                        console.log(
                                                          "Event:",
                                                          selectedEvent._id
                                                        );
                                                        if (selectedEvent) {
                                                          setEventId(
                                                            selectedEvent._id
                                                          );
                                                          setEventName(
                                                            selectedEvent.name
                                                          );
                                                        } else {
                                                          // Handle the case when "Select an event" is chosen
                                                          setEventId("");
                                                          setEventName("");
                                                        }
                                                      }}
                                                    >
                                                      <option value="">
                                                        Select an event
                                                      </option>
                                                      {events &&
                                                        events.map(
                                                          (index, id) => (
                                                            <option
                                                              key={id}
                                                              value={index._id}
                                                            >
                                                              {index
                                                                ? index.name
                                                                : ""}
                                                            </option>
                                                          )
                                                        )}
                                                    </Input>
                                                  </div>
                                                </Col>
                                              </Row>
                                              {/* cover image  */}
                                              <Row>
                                                <div className="row justify-content-center">
                                                  <Col className="mb-3">
                                                    <div className="form-group">
                                                      <Label for="image">
                                                        Cover Image
                                                      </Label>
                                                      <Input
                                                        type="file"
                                                        className="form-control"
                                                        id="image"
                                                        //multiple
                                                        onChange={(e) => {
                                                          e.target.files[0]
                                                            ? setPreview({
                                                                imgSrc:
                                                                  URL.createObjectURL(
                                                                    e.target
                                                                      .files[0]
                                                                  ),
                                                              })
                                                            : setPreview({
                                                                imgSrc: null,
                                                              });
                                                          e.target.files[0]
                                                            ? setImage(
                                                                e.target
                                                                  .files[0]
                                                              )
                                                            : setImage();
                                                        }}
                                                      />
                                                      {preview.imgSrc && (
                                                        <img
                                                          src={preview.imgSrc}
                                                          alt="preview"
                                                          className="mt-3"
                                                          height="150px"
                                                        />
                                                      )}
                                                    </div>
                                                  </Col>
                                                </div>
                                              </Row>
                                              {/* images */}
                                              <Row>
                                                <div className="row justify-content-center">
                                                  <Col className="mb-3">
                                                    <label htmlFor="resume">
                                                      Upload Images* (minimum 2)
                                                    </label>
                                                    <Input
                                                      type="file"
                                                      className="form-control"
                                                      id="resume"
                                                      multiple
                                                      onChange={(e) => {
                                                        const filesArray =
                                                          Array.from(
                                                            e.target.files
                                                          ); // Convert files object to array
                                                        console.log(
                                                          "Array length:",
                                                          filesArray.length
                                                        );
                                                        setImages(filesArray);
                                                      }}
                                                    />
                                                  </Col>
                                                </div>
                                              </Row>
                                              {/* video */}
                                              <Row>
                                                <div className="row justify-content-center">
                                                  <Col className="mb-3">
                                                    <label htmlFor="resume">
                                                      Upload Video
                                                    </label>
                                                    <Input
                                                      type="file"
                                                      className="form-control"
                                                      id="video"
                                                      onChange={(e) =>
                                                        setVideo(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                </div>
                                              </Row>
                                            </Form>
                                          </div>
                                          <div className="modal-footer">
                                            <Button
                                              color="primary"
                                              className={`btn btn-primary ${
                                                eventName &&
                                                image !== undefined &&
                                                images.length > 1
                                                  ? ""
                                                  : "disabled"
                                              }`}
                                              onClick={() =>
                                                changeGallery(
                                                  index && index._id
                                                )
                                              }
                                            >
                                              Update
                                            </Button>
                                          </div>
                                        </Modal>
                                      </Col>
                                      <Col
                                        md={6}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          color="primary"
                                          className="btn btn-primary waves-effect waves-light m-1"
                                          onClick={() =>
                                            deleteGallery(index && index._id)
                                          }
                                          style={{
                                            width: 40,
                                            fontSize: 17,
                                            height: 40,
                                            borderRadius: "50%",

                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <i className="uil uil-trash-alt"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            );
                          })}
                      </Row>
                      {gallery && gallery.length > 0 ? (
                        <Row>
                          {/* Pagination controls */}
                          <Col
                            md={12}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              style={{ fontSize: 16 }}
                            >
                              <i className="uil uil-arrow-left"></i>
                              Prev
                            </Button>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ fontSize: 16 }}>
                                {currentPage + "/" + totalPages}
                              </span>
                            </div>
                            <Button
                              color="primary"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              style={{ fontSize: 16 }}
                            >
                              Next
                              <i className="uil uil-arrow-right"></i>
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col sm={12}>
                            <h3 style={{ textAlign: "center" }}>
                              No data available.
                            </h3>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
                {/* <Col mg={6} xl={4}>
                  <Col md="12">
                    <div className="form-inline">
                      <div className="search-box ml-2">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control bg-white border-light"
                            style={{ borderRadius: 15 }}
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <i className="mdi mdi-magnify search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md="12" className="mt-2">
                    <Card
                      style={{
                        borderRadius: 20,
                      }}
                    >
                      <CardBody>
                        <FullCalendar
                          plugins={[dayGridPlugin, interactionPlugin]}
                          initialView="dayGridMonth"
                          events={formattedEvents && formattedEvents} // Pass the formatted events to FullCalendar
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          // events={events}
                          // editable={true}
                          // droppable={true}
                          // selectable={true}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Col> */}
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default EditGallery;
