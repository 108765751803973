import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Modal,
  Form,
  Spinner,
  Button,
} from "reactstrap";
import setupanalytics from "../../assets/images/setup-analytics-amico.svg";
import Sidebar from "../../components/VerticalLayout/Sidebar";
import Header from "../../components/VerticalLayout/Header";

const Membership = () => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_backdrop1, setmodal_backdrop1] = useState(false);
  const [name, setName] = useState();
  const [duration, setDuration] = useState();
  const [amount, setAmount] = useState();
  const [list, setList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [association, setAssociation] = useState();
  const [searchName, setSearchName] = useState("");
  const [searchAssociation, setSearchAssociation] = useState("");
  const [indexId, setIndexId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const MAX_PAGE_NUMBERS = 10;

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const getAssociation = (arr, emailId) => {
    //console.log(emailId);
    let asso = (arr ?? []).find((_association) => _association._id === emailId);
    return asso && asso.name ? asso.name : "Default Name";
  };
  const filteredList = list
    .filter((doctor) =>
      doctor.name.toLowerCase().includes(searchName.toLowerCase())
    )
    .filter((doctor) =>
      getAssociation(association, doctor.association)
        .toLowerCase()
        .includes(searchAssociation.toLowerCase())
    );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const history = useHistory();

  useEffect(() => {
    const isauthUser = localStorage.getItem("isauthUser");
    if (isauthUser === "false") {
      // Redirect to the login page programmatically
      history.push("/");
    }
  }, [history]);

  // for the new membership listing POST API
  function addMembership() {
    if (name && duration && amount) {
      console.warn({
        duration,
        amount,
        name,
      });
      let data = {
        duration,
        amount,
        name,
      };
      fetch(`${REACT_APP_API_ENDPOINT}/membership/plan`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((result) => {
        // console.warn("result", result);
        result.json().then((resp) => {
          if (result.status == 200) {
            // console.warn("resp", resp);
            // localStorage.setItem("isAddMembership", true);
            setName("");
            setAmount("");
            setDuration("");
            window.open("/membership", "_self");
          } else {
            alert("please enter correct credentials");
          }
        });
      });
    } else {
      alert("Please fill all details!");
    }
  }

  // for showing the different membership on cards GET API for cards
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const jwt = localStorage.getItem("jwt");
  myHeaders.append("x-api-key", jwt);
  console.log(localStorage.getItem("jwt"));

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/membership/plan`, requestOptions)
      .then((response) => response.json())
      .then((result) => setMembershipList(result))
      .catch((error) => console.log("error", error));
  }, []);

  // for showing list of doctors who has taken memberships GET API for list
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    setIsLoading(true);
    const url = new URL(`${REACT_APP_API_ENDPOINT}/doctor`);
    url.searchParams.append("name", searchName);
    url.searchParams.append("association", searchAssociation);

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        setList(result);
      })
      .catch((error) => console.log("error", error));
  }, [searchName, searchAssociation]);

  // / For Deleting the mempership

  function deleteUser(_id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", jwt);
    var raw = JSON.stringify({
      membershipId: _id,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(_id);
    fetch(`${REACT_APP_API_ENDPOINT}/membership`, requestOptions).then(
      (result) => {
        result.json().then((response) => {
          console.warn(response);
          window.open("/membership", "_self");
        });
      }
    );
  }

  // for updating the membership
  function updateMembership(_id) {
    if (name && duration && amount) {
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", jwt);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: name,
        duration: duration,
        amount: amount,
        id: _id,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${REACT_APP_API_ENDPOINT}/membership/plan`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setName("");
          setAmount("");
          setDuration("");
          //console.log(result);
          window.open("/membership", "_self");
        })
        .catch((error) => console.log("error", error));
    } else {
      alert("Please fill all details!");
    }
  }

  // Association k dropdown  liye calling get api here
  var myHeaders = new Headers();
  console.log(localStorage.getItem("jwt"));
  myHeaders.append("x-api-key", jwt);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/association`, requestOptions)
      .then((response) => response.json())
      .then((result) => setAssociation(result))
      .catch((error) => console.log("error", error));
  }, []);

  function tog_backdrop1(index) {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
    setName(membershipList[index].name);
    setDuration(membershipList[index].duration);
    setAmount(membershipList[index].amount);
  }

  // function of madal starts from here
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function tog_backdrop1() {
    setmodal_backdrop1(!modal_backdrop1);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const renderPageNumbers = () => {
    const totalPageNumbers = Math.ceil(
      (filteredList && filteredList.length) / itemsPerPage
    );

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_NUMBERS / 2));
    let endPage = Math.min(startPage + MAX_PAGE_NUMBERS - 1, totalPageNumbers);

    // Adjust the startPage if needed
    if (endPage - startPage + 1 < MAX_PAGE_NUMBERS) {
      startPage = Math.max(1, endPage - MAX_PAGE_NUMBERS + 1);
    }

    const pageNumbersToDisplay = [];

    for (let i = startPage; i <= endPage; i++) {
      pageNumbersToDisplay.push(i);
    }

    return pageNumbersToDisplay.map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
      >
        <a
          className="page-link"
          onClick={() => handlePaginationClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: 0 }}>
        <Row>
          <Col lg={2}>
            <Sidebar />
          </Col>
          <Col lg={10}>
            <Container fluid={true}>
              <Header />

              <Row style={{ marginTop: 85 }}>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        {membershipList &&
                          membershipList.map((membership, index) => (
                            <Col key={index} xl={6}>
                              <Card className="bg-primary">
                                <CardBody>
                                  <Row className="align-items-center">
                                    <Col sm={8}>
                                      <p className="text-white font-size-18">
                                        <b>
                                          {membership ? membership.name : null}
                                        </b>{" "}
                                      </p>
                                      <p className="text-white font-size-16">
                                        Membership plan for{" "}
                                        {membership
                                          ? membership.duration
                                          : null}{" "}
                                        months
                                      </p>
                                      <p className="text-white font-size-16">
                                        ₹{" "}
                                        {membership ? membership.amount : null}
                                      </p>
                                      <Row>
                                        <Col>
                                          <button
                                            type="button"
                                            className="btn btn-warning upgrade-4 waves-effect waves-light"
                                            onClick={() => {
                                              setName(membership.name);
                                              setDuration(membership.duration);
                                              setAmount(membership.amount);
                                              setIndexId(membership._id);
                                              tog_backdrop1();
                                            }}
                                            data-toggle="modal"
                                          >
                                            Edit
                                          </button>
                                          <Modal
                                            isOpen={modal_backdrop1}
                                            toggle={() => {
                                              tog_backdrop1(index);
                                            }}
                                            scrollable={true}
                                            id="staticBackdrop"
                                          >
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="staticBackdropLabel"
                                              >
                                                Edit Membership plan
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                onClick={() => {
                                                  setmodal_backdrop1(false);
                                                }}
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <Form>
                                                <Row>
                                                  <Col className="mb-1">
                                                    <label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Membership Name*
                                                    </label>
                                                    <div>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => {
                                                          setName(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col className="mb-1">
                                                    <label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Duration of the
                                                      Membership*
                                                    </label>
                                                    <div>
                                                      <input
                                                        className="form-control"
                                                        type="number"
                                                        value={duration}
                                                        onChange={(e) => {
                                                          setDuration(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col className="mb-1">
                                                    <label
                                                      htmlFor="example-text-input"
                                                      className="col-form-label"
                                                    >
                                                      Enter Amount (₹)*
                                                    </label>
                                                    <div>
                                                      <input
                                                        className="form-control"
                                                        type="number"
                                                        value={amount}
                                                        onChange={(e) => {
                                                          setAmount(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Form>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  updateMembership(indexId)
                                                }
                                              >
                                                Update
                                              </button>
                                            </div>
                                          </Modal>
                                        </Col>
                                        <Col>
                                          <button
                                            type="button"
                                            className="btn btn-warning upgrade-4 waves-effect waves-light"
                                            onClick={() => {
                                              deleteUser(membership._id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col sm={4}>
                                      <div className="mt-4 mt-sm-0">
                                        <img
                                          src={setupanalytics}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                      </Row>

                      <Row>
                        <Col lg={2}>
                          <h3>Members List</h3>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by Name"
                              value={searchName}
                              onChange={(e) => setSearchName(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search by Association"
                              value={searchAssociation}
                              onChange={(e) =>
                                setSearchAssociation(e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div className="mb-2">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => {
                                tog_backdrop();
                              }}
                              data-toggle="modal"
                            >
                              <i className="dripicons-plus" /> Add New Plan
                            </button>
                            <Modal
                              isOpen={modal_backdrop}
                              toggle={() => {
                                tog_backdrop();
                              }}
                              scrollable={true}
                              id="staticBackdrop"
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="staticBackdropLabel"
                                >
                                  Create a new Membership plan
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  onClick={() => {
                                    setmodal_backdrop(false);
                                  }}
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <Form>
                                  <Row>
                                    <Col className="mb-1">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                      >
                                        Membership Name*
                                      </label>
                                      <div>
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={name}
                                          required={true}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mb-1">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                      >
                                        Duration of the Membership (Months)*
                                      </label>
                                      <div>
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={duration}
                                          required={true}
                                          onChange={(e) =>
                                            setDuration(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mb-1">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                      >
                                        Enter Amount (₹)*
                                      </label>
                                      <div>
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={amount}
                                          required={true}
                                          onChange={(e) =>
                                            setAmount(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className={`btn btn-primary ${
                                    name && amount && duration ? "" : "disabled"
                                  }`}
                                  onClick={addMembership}
                                >
                                  Add
                                </button>
                              </div>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                      {isLoading ? (
                        <Button
                          color="primary"
                          style={{
                            position: "absolute",
                            top: 200,
                            left: "50%",
                          }}
                          disabled
                        >
                          <Spinner size="sm">Loading...</Spinner>
                          <span> Loading</span>
                        </Button>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-bordered border-dark mb-0 text-center">
                            <thead>
                              <tr>
                                <th>Sr.No</th>
                                <th>Doctor Name</th>
                                <th>Association Name</th>
                                <th>Buying Date</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                                <th>Membership</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems.map((index, id) => (
                                <tr key={id}>
                                  <th scope="row">
                                    {(currentPage - 1) * itemsPerPage + id + 1}
                                  </th>
                                  <td>{index ? index.name : null}</td>
                                  <td>
                                    {index
                                      ? getAssociation(
                                          association,
                                          index.association
                                        )
                                      : null}
                                  </td>
                                  <td>
                                    {index.plan
                                      ? new Date(
                                          index.plan.startDate
                                        ).toLocaleDateString("en-GB")
                                      : null}
                                  </td>
                                  <td>
                                    {index.plan
                                      ? new Date(
                                          index.plan.expiryDate
                                        ).toLocaleDateString("en-GB")
                                      : "-"}
                                  </td>
                                  <td className="text-success">
                                    {index.plan ? index.plan.status : null}
                                  </td>
                                  <td>{index.plan ? index.plan.name : null}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* Pagination */}
                          <div className="d-flex justify-content-center">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className={`page-item ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handlePreviousClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Previous
                                  </a>
                                </li>
                                {renderPageNumbers()}
                                <li
                                  className={`page-item ${
                                    currentPage === pageNumbers.length
                                      ? "disabled"
                                      : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    onClick={handleNextClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            {/*<ul className="pagination">
                              {[
                                ...Array(
                                  Math.ceil(filteredList.length / itemsPerPage)
                                ).keys(),
                              ].map((number) => (
                                <li
                                  key={number + 1}
                                  className={
                                    currentPage === number + 1 ? "active" : ""
                                  }
                                >
                                  <button
                                    onClick={() => setCurrentPage(number + 1)}
                                  >
                                    {number + 1}
                                  </button>
                                </li>
                              ))}
                                </ul>*/}
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Membership;
